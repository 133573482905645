import {writable, type Readable, type Writable} from "svelte/store";
import {Loadable} from "../loader";
import {cg_support_api, type DefaultApiTypes} from "../api";

export interface Param {
  name: string;
  value: string;
}

interface InfoList {
  items: {[name: string]: string};
}

interface ApiTypes extends DefaultApiTypes {
  get_result: InfoList;
}

export class Redis extends Loadable<InfoList> {
  #info: Writable<Param[]>;

  constructor() {
    super();

    this.#info = writable([]);
  }

  get info(): Readable<Param[]> {
    return this.#info;
  }

  async $load(): Promise<InfoList> {
    return cg_support_api<ApiTypes>("/api/v1/services/redis-info").get();
  }

  $validate(data: InfoList): boolean {
    return typeof data === "object" && data !== null && typeof data.items === "object" && data.items !== null;
  }

  $set(data: InfoList): void {
    this.#info.set(Object.entries(data.items).map(([name, value]) => ({name, value})));
  }

  $clear(): void {
    this.#info.set([]);
  }
}
