<script context="module" lang="ts">
  import {RadSec} from "$app/support/api/radsec";
  import {params_equal} from "$app/support/api/radsec/proxy_connections";
  import type {
    Params,
    RadSecProxyConnection as RadSecProxyConnectionData,
  } from "$app/support/api/radsec/proxy_connections";
  import type {Readable} from "svelte/store";
  import Spinner from "$lib/spinkit/Spinner.svelte";
  import RadSecProxyConnection from "./RadSecProxyConnection.svelte";

  interface ModifiedRadSecProxyConnection extends RadSecProxyConnectionData {
    $show_detail?: boolean;
  }

  function order_ips(ips: string[]): string[] {
    // Copy then sort
    ips = [...ips];
    ips.sort();
    return ips;
  }
</script>

<script lang="ts">
  const options: Params = {
    instance: "",
    proxy_to: "",
  };

  let applied_options: Params | undefined = undefined;

  const radsec = new RadSec();
  const ips = radsec.instances.ips;
  const connections: Readable<ModifiedRadSecProxyConnection[]> = radsec.proxy_connections.connections;
  const connections_loading = radsec.proxy_connections.$loading;
  const connections_error = radsec.proxy_connections.$error;

  function refresh() {
    radsec.instances.refresh();
    radsec.proxy_connections.set_instance(options.instance);
    radsec.proxy_connections.set_proxy_to(options.proxy_to);
    radsec.proxy_connections.refresh().then(function (data) {
      applied_options = {...options};
      return data;
    });
  }

  $: applied = applied_options && params_equal(options, applied_options);

  radsec.instances.refresh();
</script>

<!-- CONTENT -->
<div class="container">
  <!-- PAGE TITLE -->
  <div class="row">
    <div class="col-sm-offset-2 col-sm-10">
      <h1>RadSec Proxy Connections</h1>
    </div>
  </div>

  <form class="form-horizontal">
    <div class="form-group">
      <!-- svelte-ignore a11y-label-has-associated-control -->
      <label class="control-label col-sm-2">Proxy To</label>
      <div class="col-sm-8">
        <div class="row">
          <div class="col-xs-8 col-sm-10">
            <input bind:value={options.proxy_to} class="form-control" />
          </div>
        </div>
        <p class="help-block">
          Enter the <strong>proxy to</strong> target a <strong>proxy</strong> to filter the list of connections.
        </p>
      </div>
    </div>
    <div class="form-group">
      <!-- svelte-ignore a11y-label-has-associated-control -->
      <label class="control-label col-sm-2">Servers</label>
      <div class="col-sm-8">
        <div class="input-group">
          <select bind:value={options.instance} class="form-control">
            <option value="">All instances</option>
            {#each order_ips($ips) as ip}
              <option value={ip}>{ip}</option>
            {/each}
          </select>
        </div>
        <p class="help-block">Select the <strong>RadSec Server</strong> instance to list connections from.</p>
      </div>
    </div>
    <div class="form-group">
      <div class="col-sm-offset-2 col-sm-8">
        {#if !applied}
          <button on:click|preventDefault={refresh} class="btn btn-primary">Apply</button>
        {/if}
        {#if applied}
          <button on:click|preventDefault={refresh} class="btn btn-primary">
            <span class="glyphicon glyphicon-refresh" />
            <span class="hidden-xs">Refresh</span>
          </button>
        {/if}
      </div>
    </div>
  </form>

  <hr />

  {#if $connections_loading}
    <div class="row">
      <div class="col-xs-12 col-sm-offset-2 col-sm-8">
        <div class="alert alert-info"><Spinner type="circle-fade" /> Loading</div>
      </div>
    </div>
  {/if}

  {#if $connections_error}
    <div class="row">
      <div class="col-xs-12 col-sm-offset-2 col-sm-8">
        <div class="alert alert-danger">Failed to retrieve the list of connections.</div>
      </div>
    </div>
  {/if}

  {#if applied && !$connections.length}
    <div class="row">
      <div class="col-xs-12 col-sm-offset-2 col-sm-8">
        <div class="alert alert-info"><span class="glyphicon glyphicon-info-sign" /> No connections.</div>
      </div>
    </div>
  {/if}

  {#if $connections.length}
    <div class="row hidden-xs stack-1">
      <div class="col-sm-3 col-md-4"><strong>IP</strong></div>
      <div class="col-sm-4 col-md-3"><strong>Proxy To</strong></div>
      <div class="col-sm-5 col-md-5"><strong /></div>
    </div>
  {/if}

  {#each $connections as connection}
    <div class="row">
      <div class="col-xs-12 col-sm-3 col-md-4 stack-1">
        <button
          on:click|preventDefault={() => {
            connection.$show_detail = !connection.$show_detail;
          }}
          class:active={connection.$show_detail}
          class="btn btn-xs btn-default"
        >
          <span>{connection.remote_addr}</span>
          <span class="hidden-sm">
            <span class="glyphicon glyphicon-chevron-right" />
            <span>{connection.local_addr}</span>
          </span>
        </button>
      </div>

      {#if connection.$show_detail}
        <div class="visible-xs">
          <div class="col-xs-offset-4 col-sm-offset-3 col-md-offset-4 col-xs-8 col-sm-9 col-md-8 stack-2">
            <strong>Identification</strong>
          </div>

          <div class="col-xs-4 col-sm-3 col-md-4 text-right stack-1">
            <strong>IP</strong>
          </div>
          <div class="col-xs-8 col-sm-9 col-md-8 stack-1">
            <span>{connection.remote_addr}</span>
            <span class="glyphicon glyphicon-chevron-right" />
            <span>{connection.local_addr}</span>
          </div>
        </div>
      {/if}

      <div class="col-xs-4 visible-xs stack-1 text-right">
        <strong>Proxy To</strong>
      </div>
      <div class="col-xs-8 col-sm-4 col-md-3 stack-1">
        <span>{connection.proxy_to}</span>
      </div>
    </div>

    {#if connection.$show_detail}
      <RadSecProxyConnection {connection} />
    {/if}
  {/each}
</div>
