import {cg_support_api, type DefaultApiTypes} from "../api";

export type RoleName = string;

export interface RolesList {
  items: RoleName[];
}

interface ApiTypes extends DefaultApiTypes {
  get_result: RolesList;
}

export function service_roles_api() {
  return cg_support_api<ApiTypes>("/api/v1/services/roles");
}
