<script lang="ts">
  import {send_sms} from "$app/support/api/messaging/send_sms";
  import type {SmsSendResult} from "$app/support/api/messaging/send_sms";
  import Alert from "$lib/Alert.svelte";
  import Phone from "$lib/Phone.svelte";
  import Spinner from "$lib/spinkit/Spinner.svelte";

  const sms = {
    local_phone: "",
    phone: "",
    scope: "",
    queue: "queue" as "queue" | "bypass",
    status: "ready" as "ready" | "sending" | "success" | "failure",
    sid: undefined as SmsSendResult["sid"],
    error_msg: undefined as SmsSendResult["error_msg"],
  };

  function send() {
    sms.status = "sending";
    send_sms()
      .post(undefined, {
        phone_number: sms.phone,
        scope: sms.scope === "" ? undefined : sms.scope,
        use_queue: sms.queue === "queue",
      })
      .then(
        function (data) {
          if (!data.error_msg) {
            sms.status = "success";
            sms.sid = data.sid;
            sms.error_msg = undefined;
          } else {
            sms.status = "failure";
            sms.sid = undefined;
            sms.error_msg = data.error_msg;
          }
          return data;
        },
        function (reason) {
          sms.status = "failure";
          sms.sid = undefined;
          sms.error_msg = `HTTP status ${reason.status}`;
          return Promise.reject(reason);
        }
      );
  }

  function acknowledge() {
    sms.status = "ready";
  }

  $: can_send = !!sms.phone;
</script>

<div>
  <!-- CONTENT -->
  <div class="container">
    <div class="row">
      <div class="col-sm-offset-2 col-sm-10">
        <h1 id="sms-send-heading">Send SMS</h1>
      </div>
    </div>

    <form class="form-horizontal" name="send_sms_form">
      <div class="form-group">
        <!-- svelte-ignore a11y-label-has-associated-control -->
        <label class="col-sm-2 control-label">Send to</label>
        <div class="col-sm-8">
          <Phone bind:international_number={sms.phone} class="form-control" id="phone_input" required />
          {#if !sms.phone}
            <p class="help-block">Enter a phone number to send a test SMS message to.</p>
          {:else}
            <p class="help-block">
              A test SMS message will be sent to the the
              <strong>international</strong>
              number:
              <strong>{sms.phone}</strong>
            </p>
          {/if}
        </div>
      </div>
      <div class="form-group">
        <!-- svelte-ignore a11y-label-has-associated-control -->
        <label class="col-sm-2 control-label">Scope</label>
        <div class="col-sm-4">
          <input bind:value={sms.scope} name="scope" class="form-control" />
          <p class="help-block">Scope that is related to this SMS.</p>
        </div>
      </div>

      <div class="form-group">
        <!-- svelte-ignore a11y-label-has-associated-control -->
        <label class="col-sm-2 control-label">Queue</label>
        <div class="col-sm-8">
          <select bind:value={sms.queue} class="form-control" id="queue-selector" required>
            <option value="queue">Queued — Use standard queue</option>
            <option value="bypass">Bypass — Direct to provider</option>
          </select>
          {#if sms.queue == "queue"}
            <p class="help-block">
              The SMS message will be
              <strong>queued</strong> for delivery on the same standard message queue as used by the main application.
            </p>
            <p class="help-block">
              Use this option to verify that SMS message queue processing as well as SMS delivery is working as
              expected.
            </p>
          {/if}
          {#if sms.queue == "bypass"}
            <p class="help-block">
              The SMS message will be sent
              <strong>directly</strong> to the SMS provider. The standard message queue as used by the main application
              will <strong>not</strong> be used.
            </p>
            <p class="help-block">
              Use this option to
              <strong>bypass</strong> the standard message queue and only verify that the SMS provider is working as expected.
            </p>
          {/if}
        </div>
      </div>
      <div class="form-group">
        <div class="col-sm-offset-2 col-sm-8">
          {#if sms.status == "ready"}
            <button on:click|preventDefault={send} disabled={!can_send} class="btn btn-lg btn-primary" id="send-button">
              Send
            </button>
          {/if}
          {#if sms.status == "sending"}
            <button disabled class="btn btn-lg btn-primary">
              Sending
              <Spinner type="circle-fade" />
            </button>
          {/if}
          {#if sms.status == "success"}
            <Alert type="success" close={acknowledge}>Message sent. SID: <span id="sms-id">{sms.sid}</span></Alert>
          {/if}
          {#if sms.status == "failure"}
            <Alert type="danger" close={acknowledge}>
              Failed to send the message. Error: <span id="sms-error-msg">{sms.error_msg}</span>
            </Alert>
          {/if}
        </div>
      </div>
    </form>
  </div>
</div>
