import {get, writable, type Readable, type Subscriber, type Writable} from "svelte/store";
import {service_roles_api} from "./service_roles_api";
import {service_health_api, type HealthCheckResult} from "./service_health_api";
import {service_role_api} from "./service_role_api";

type NotifyFn = () => void;
interface Counters {
  pass: number;
  fail: number;
  error: number;
  loading: number;
}
export type Status = "fail" | "error" | "loading" | "pass";

function aggregated(a: Counters, b: Counters): Counters {
  var result = {...a};

  let key: keyof Counters;
  for (key in b) {
    result[key] += b[key];
  }

  return result;
}

function normalized(counters: Counters) {
  let total: number = 0;
  let result: Counters = {pass: 0, fail: 0, error: 0, loading: 0};

  let key: keyof Counters;
  for (key in counters) {
    total += counters[key];
  }

  for (key in counters) {
    if (total > 0.001) {
      result[key] = counters[key] / total;
    } else {
      result[key] = 0;
    }
  }

  return result;
}

function percentage(counters: Counters, key: keyof Counters) {
  return (counters[key] || 0) * 100;
}

function status_counters(counters: Counters): Status {
  if (counters.fail) {
    return "fail";
  } else if (counters.error) {
    return "error";
  } else if (counters.loading) {
    return "loading";
  } else {
    return "pass";
  }
}

export class RoleInstance {
  #name: string;
  #ip: string;
  #loading: boolean;
  #error: boolean;
  #health?: HealthCheckResult;

  constructor(name: string, ip: string) {
    this.#name = name;
    this.#ip = ip;
    this.#loading = false;
    this.#error = false;
    this.#health = undefined;
  }

  async refresh(notify: NotifyFn): Promise<void> {
    if (!this.#loading) {
      this.#loading = true;
      this.#error = false;
      this.#health = undefined;
      return service_health_api(this.#name, this.#ip)
        .get()
        .then(
          (data) => {
            this.#loading = false;
            this.#error = false;
            this.#health = data;
            notify();
          },
          (reason) => {
            this.#loading = false;
            this.#error = true;
            this.#health = undefined;
            notify();
          }
        );
    }
  }

  get name() {
    return this.#name;
  }

  get ip() {
    return this.#ip;
  }

  get loading() {
    return this.#loading;
  }

  get error() {
    return this.#error;
  }

  get health() {
    return this.#health;
  }

  status(): Status {
    if (this.#loading) {
      return "loading";
    } else if (this.#error) {
      return "error";
    } else if (!this.#health) {
      return "loading";
    } else if (this.#health.success !== true) {
      return "fail";
    } else {
      return "pass";
    }
  }

  counters(): Counters {
    const counters: Counters = {pass: 0, fail: 0, error: 0, loading: 0};

    if (this.#loading) {
      counters.loading = 1;
    } else if (this.#error) {
      counters.error = 1;
    } else if (this.#health) {
      for (const [check, message] of Object.entries(this.#health.checks)) {
        if (message === null) {
          counters.pass++;
        } else {
          counters.fail++;
        }
      }
    }

    return normalized(counters);
  }

  percentage(key: keyof Counters): number {
    return percentage(this.counters(), key);
  }
}

export class Role {
  #name: string;
  #loading: boolean;
  #error: boolean;
  #instances: RoleInstance[];

  constructor(name: string) {
    this.#name = name;
    this.#loading = false;
    this.#error = false;
    this.#instances = [];
  }

  async refresh(notify: NotifyFn): Promise<void> {
    if (!this.#loading) {
      this.#loading = true;
      this.#error = false;
      this.#instances = [];
      return service_role_api(this.#name)
        .get()
        .then(
          async (data) => {
            if (data.items.length === 0) {
              this.#loading = false;
              this.#error = true;
              notify();
            } else {
              this.#loading = false;
              this.#error = false;
              for (const ip of data.items) {
                this.#instances.push(new RoleInstance(this.#name, ip));
              }
              notify();
              for (const instance of this.#instances) {
                // Use await to serialise refresh of instances
                await instance.refresh(notify);
              }
              notify();
            }
          },
          (reason) => {
            this.#loading = false;
            this.#error = true;
            notify();
          }
        );
    }
  }

  get name() {
    return this.#name;
  }

  get loading() {
    return this.#loading;
  }

  get error() {
    return this.#error;
  }

  get instances() {
    return this.#instances;
  }

  status(): Status {
    if (this.#loading) {
      return "loading";
    } else if (this.#error) {
      return "error";
    } else {
      return status_counters(this.counters());
    }
  }

  counters(): Counters {
    let counters: Counters = {pass: 0, fail: 0, error: 0, loading: 0};

    if (this.#loading) {
      counters.loading = 1;
    } else if (this.#error) {
      counters.error = 1;
    } else {
      for (const instance of this.#instances) {
        counters = aggregated(counters, normalized(instance.counters()));
      }
    }

    return normalized(counters);
  }

  percentage(key: keyof Counters): number {
    return percentage(this.counters(), key);
  }
}

export class SystemHealth implements Readable<SystemHealth> {
  #loading: boolean;
  #error: boolean;
  #roles: Role[];
  #store: Writable<SystemHealth>;

  subscribe: Readable<SystemHealth>["subscribe"];

  constructor() {
    this.#loading = false;
    this.#error = false;
    this.#roles = [];
    this.#store = writable(this);
    this.subscribe = this.#store.subscribe;
  }

  #update({loading, error, roles}: {loading?: boolean; error?: boolean; roles?: Role[]}): void {
    this.#loading = loading ?? false;
    this.#error = error ?? false;
    this.#roles = roles ?? [];
    this.#notify();
  }

  #notify(): void {
    this.#store.set(this);
  }

  refresh(): void {
    if (!this.#loading) {
      this.#update({loading: true, roles: []});
      service_roles_api()
        .get()
        .then(
          (data) => {
            const roles: Role[] = [];
            for (const name of data.items) {
              roles.push(new Role(name));
            }
            this.#update({roles});
            for (const role of roles) {
              role.refresh(() => this.#notify());
            }
          },
          (reason) => {
            this.#update({error: true, roles: []});
          }
        );
    }
  }

  get loading() {
    return this.#loading;
  }

  get error() {
    return this.#error;
  }

  get roles() {
    return this.#roles;
  }

  status(): Status {
    if (this.#loading) {
      return "loading";
    } else if (this.#error) {
      return "error";
    } else {
      return status_counters(this.counters());
    }
  }

  counters(): Counters {
    let counters: Counters = {pass: 0, fail: 0, error: 0, loading: 0};

    if (this.#loading) {
      counters.loading = 1;
    } else if (this.#error) {
      counters.error = 1;
    } else {
      for (const role of this.#roles) {
        counters = aggregated(counters, normalized(role.counters()));
      }
    }

    return normalized(counters);
  }

  percentage(key: keyof Counters): number {
    return percentage(this.counters(), key);
  }
}
