<script lang="ts">
  export let label: string;
  export let value: string | undefined = undefined;
  export let cell: boolean | undefined = undefined;
  export let overflow: boolean | undefined = undefined;
  let classes: string | undefined = undefined;
  export {classes as class};
</script>

<div class="row">
  <div class="col-xs-2 text-right {classes ?? ''}" class:hidden-lg={cell}>
    <span class="label label-default">{label}</span>
  </div>
  <div class="col-xs-10" class:col-lg-12={cell} class:text-overflow-lg={overflow}>
    <slot>
      <code>{value}</code>
    </slot>
  </div>
</div>
