<script context="module" lang="ts">
  import {require_nav_context} from "$lib/nav/context";
  import {require_system_info} from "$app/support/system_info/context";
  import InspectEndpoint from "./InspectEndpoint.svelte";
  import type {Params} from "./types";

  interface Options extends Params {
    path?: string;
  }
</script>

<script lang="ts">
  export let base: string;
  export let path: string | undefined = undefined;
  export let params: Params | undefined = undefined;

  const nav = require_nav_context();

  let options: Options = {};
  let inspect: {path: string; params: Params} | undefined = undefined;
  const system_info = require_system_info();

  function update(path?: string, params?: Params) {
    options = params ? {...params} : {};
    options = {calculate_count: true, private_info: false, path, ...options};

    if (path) {
      const new_inspect = {path, params: {...options}};
      delete new_inspect.params.path;
      inspect = new_inspect;
    } else {
      inspect = undefined;
    }
  }

  function apply() {
    const params = {...options};
    if (params.calculate_count) {
      delete params.calculate_count;
    }
    if (!params.private_info) {
      delete params.private_info;
    }
    delete params.path;
    nav.navigate(options.path ? base + options.path : base, {...params});
  }

  function clear_search() {
    delete options.search;
    apply();
  }

  function clear_paging() {
    delete options.offset;
    delete options.limit;
    apply();
  }

  function first_page() {
    delete options.offset;
    apply();
  }

  function previous_page() {
    options.limit = typeof options.limit === "number" ? options.limit : 10;
    options.offset = (typeof options.offset === "number" ? options.offset : 0) - options.limit;
    if (options.offset < 0) {
      options.offset = 0;
    }
    apply();
  }

  function next_page() {
    options.limit = typeof options.limit === "number" ? options.limit : 10;
    options.offset = (typeof options.offset === "number" ? options.offset : 0) + options.limit;
    apply();
  }

  $: update(path, params);
</script>

<div class="container">
  <div class="row">
    <div class="col-sm-offset-2 col-sm-10">
      <h1>Inspect</h1>
    </div>
  </div>

  <form class="form-horizontal" name="inspect_form">
    <div class="form-group">
      <!-- svelte-ignore a11y-label-has-associated-control -->
      <label class="col-sm-2 control-label">Path</label>
      <div class="col-sm-8">
        <input bind:value={options.path} class="form-control" placeholder="e.g. /scope.cust-12345678/portals" />
        <p class="help-block">Enter the <code>path</code> of the API endpoint to <strong>GET</strong>.</p>
      </div>
    </div>
    <div class="form-group">
      <!-- svelte-ignore a11y-label-has-associated-control -->
      <label class="col-sm-2 control-label">List options</label>
      <div class="col-sm-8">
        <p class="help-block">
          If the <strong>path</strong> being retrieved is a <strong>list</strong>, an optional <code>search</code> query
          parameter can be used to narrow the results, and optional <code>offset</code> and <code>limit</code> query
          parameters can be used to <strong>page</strong> through the results.
        </p>
        <span class="input-group">
          <span class="input-group-addon">search</span>
          <input bind:value={options.search} class="form-control" />
          <span class="input-group-btn">
            <button on:click|preventDefault={clear_search} type="button" class="btn btn-default">
              <span class="glyphicon glyphicon-remove-circle" />
              <span class="hidden-xs">Clear</span>
            </button>
          </span>
        </span>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-6 col-sm-offset-2 col-sm-3 stack-1">
        <span class="input-group">
          <span class="input-group-addon">offset</span>
          <input bind:value={options.offset} type="number" class="form-control" />
        </span>
      </div>
      <div class="col-xs-6 col-sm-3 stack-1">
        <span class="input-group">
          <span class="input-group-addon">limit</span>
          <input bind:value={options.limit} type="number" class="form-control" />
        </span>
      </div>
      <div class="col-xs-12 col-sm-4 stack-1">
        <span class="btn-group">
          <button on:click|preventDefault={clear_paging} type="button" class="btn btn-default">
            <span class="glyphicon glyphicon-remove-circle" />
            <span class="hidden-xs hidden-sm">Clear</span>
          </button>
          <button on:click|preventDefault={first_page} type="button" class="btn btn-default">
            <span class="glyphicon glyphicon-step-backward" />
            <span class="hidden-xs hidden-sm">First</span>
          </button>
          <button on:click|preventDefault={previous_page} type="button" class="btn btn-default">
            <span class="glyphicon glyphicon-backward" />
          </button>
          <button on:click|preventDefault={next_page} type="button" class="btn btn-default">
            <span class="glyphicon glyphicon-forward" />
          </button>
        </span>
      </div>
    </div>
    <div class="form-group">
      <div class="col-sm-offset-2 col-sm-8">
        <div class="checkbox">
          <label>
            <input bind:checked={options.calculate_count} type="checkbox" /> Calculate the count of matching list items
          </label>
        </div>
      </div>
    </div>
    {#if !$system_info.disable_private_info}
      <div class="form-group">
        <!-- svelte-ignore a11y-label-has-associated-control -->
        <label class="col-sm-2 control-label">Privacy</label>
        <div class="col-sm-8">
          <div class="checkbox">
            <label>
              <input bind:checked={options.private_info} type="checkbox" /> Show personal/private information
            </label>
          </div>
          {#if options.private_info}
            <p>
              <span class="label label-warning">WARNING</span> Access to personal or private information is only to be
              used when <strong>necessary</strong>
            </p>
          {/if}
        </div>
      </div>
    {/if}
    <div class="form-group">
      <div class="col-sm-offset-2 col-sm-8">
        <button on:click|preventDefault={apply} class="btn btn-lg btn-primary">Inspect</button>
      </div>
    </div>
  </form>

  <hr />
  {#if inspect}
    <InspectEndpoint path={inspect.path} params={inspect.params} />
  {/if}
</div>
