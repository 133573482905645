import {getContext, setContext} from "svelte";
import {SystemInfo, type SystemInfoResult} from "../api/services/system_info";
import type {Readable} from "svelte/store";

const KEY = Symbol();

export function init_system_info_context() {
  const system_info = new SystemInfo();
  system_info.refresh();
  setContext(KEY, system_info);
}

export function require_system_info_api(): SystemInfo {
  const system_info = getContext(KEY);
  if (system_info instanceof SystemInfo) {
    return system_info;
  } else {
    throw "required system information context is not available; use 'init_system_info_context' to initialise";
  }
}

export function require_system_info(): Readable<SystemInfoResult> {
  return require_system_info_api().system_info;
}
