import {writable, type Readable, type Writable} from "svelte/store";
import {Loadable} from "../loader";
import {service_role_api, type ServerIpAddress, type ServersList} from "../services/service_role_api";

export class Instances extends Loadable<ServersList> {
  #ips: Writable<ServerIpAddress[]>;

  constructor() {
    super();
    this.#ips = writable([]);
  }

  get ips(): Readable<ServerIpAddress[]> {
    return this.#ips;
  }

  async $load(): Promise<ServersList> {
    return service_role_api("radius").get();
  }

  $validate(data: ServersList): boolean {
    return typeof data === "object" && data !== null && typeof data.items === "object" && data.items !== null;
  }

  $set(data: ServersList): void {
    this.#ips.set(data.items);
  }

  $clear(): void {
    this.#ips.set([]);
  }
}
