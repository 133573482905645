<script lang="ts">
  export let success: boolean | undefined = undefined;
</script>

<span>
  {#if success}
    <span class="label label-success">Success</span>
  {:else if success === false}
    <span class="label label-danger">Failure</span>
  {:else}
    <span class="label label-warning">Running</span>
  {/if}
</span>
