<script lang="ts">
  import {api_call_logs} from "$app/support/api/elasticsearch/api_calls";
  import type {ApiCallLogResult} from "$app/support/api/elasticsearch/api_calls";
  import Alert from "$lib/Alert.svelte";
  import CgDate from "$lib/CgDate.svelte";
  import Spinner from "$lib/spinkit/Spinner.svelte";

  let logs = {
    scopepath: "",
    cursor: undefined as number | undefined,
    status: "ready" as "ready" | "collecting" | "success" | "failure",
    history: undefined as ApiCallLogResult | undefined,
    timezone: "local" as "local" | "UTC",
    timezones: ["local", "UTC"] as ("local" | "UTC")[],
  };

  function cycle_timezone() {
    const index = logs.timezones.indexOf(logs.timezone);
    if (index >= 0) {
      const next = (index + 1) % logs.timezones.length;
      logs.timezone = logs.timezones[next];
    }
  }

  function collect(next: boolean) {
    logs.status = "collecting";
    logs.history = undefined;
    api_call_logs(logs.scopepath, next ? logs.cursor : undefined).then(
      function (data) {
        logs.status = "success";
        logs.history = data;
        logs.cursor = data.cursor;
        return data;
      },
      function (reason) {
        logs.status = "failure";
        return Promise.reject(reason);
      }
    );
  }

  function collect_first() {
    collect(false);
  }

  function collect_next() {
    collect(true);
  }

  function acknowledge() {
    logs.status = "ready";
  }

  function reset_form() {
    logs.status = "ready";
    logs.scopepath = "";
    clear_history();
  }

  function clear_history() {
    logs.status = "ready";
    logs.history = undefined;
  }

  $: can_collect = logs.scopepath !== "";
</script>

<div class="container">
  <div class="row">
    <div class="col-sm-offset-2 col-sm-10">
      <h1>API Call Logs</h1>
    </div>
  </div>

  <form class="form-horizontal" name="api_call_history_form">
    <div class="form-group">
      <!-- svelte-ignore a11y-label-has-associated-control -->
      <label class="col-sm-2 control-label">Timezone</label>
      <div class="col-sm-4">
        <select bind:value={logs.timezone} class="form-control">
          {#each logs.timezones as tz}
            <option value={tz}>{tz}</option>
          {/each}
        </select>
        <p class="help-block">
          Time data will be displayed in
          <strong>{logs.timezone}</strong> timezone.
        </p>
      </div>
    </div>

    <div class="form-group">
      <!-- svelte-ignore a11y-label-has-associated-control -->
      <label class="col-sm-2 control-label">Scope</label>
      <div class="col-sm-8">
        <input
          bind:value={logs.scopepath}
          on:change={clear_history}
          name="scope_name"
          autocomplete="on"
          class="form-control"
          required
          placeholder="e.g. scope.cust-12345678"
        />
        {#if !logs.scopepath}
          <p class="help-block">Enter the scope to receive the API call logs for.</p>
        {/if}
        {#if logs.scopepath && logs.status != "success"}
          <p class="help-block">
            The API call history will be retrieved for the destination address
            <strong>{logs.scopepath}</strong>.
          </p>
        {/if}
        {#if logs.status == "success"}
          <p class="help-block">
            SUCCESS: Collected
            <strong>{logs.history ? logs.history.items.length : 0}</strong> records of API calls for
            <strong>{logs.scopepath}</strong>.
          </p>
        {/if}
      </div>
    </div>

    <div class="form-group">
      <div class="col-sm-offset-2 col-sm-8">
        {#if logs.status == "ready"}
          <button on:click|preventDefault={collect_first} disabled={!can_collect} class="btn btn-lg btn-primary">
            Collect
          </button>
        {/if}
        {#if logs.status == "success"}
          <button on:click|preventDefault={collect_first} disabled={!can_collect} class="btn btn-lg btn-primary">
            <span class="glyphicon glyphicon-refresh" /> Refresh
          </button>
        {/if}
        {#if logs.status == "success" && logs.cursor !== null}
          <button on:click|preventDefault={collect_next} disabled={!can_collect} class="btn btn-lg btn-primary">
            <span class="glyphicon glyphicon-forward" /> Next
          </button>
        {/if}
        {#if logs.status == "collecting"}
          <button disabled class="btn btn-lg btn-primary">
            Collecting
            <Spinner type="circle-fade" />
          </button>
        {/if}
        {#if logs.status == "success"}
          <button on:click|preventDefault={reset_form} class="btn btn-lg btn-default">Clear</button>
        {/if}
        {#if logs.status == "failure"}
          <Alert type="danger" close={acknowledge}>Failed to collect the API call history</Alert>
        {/if}
      </div>
    </div>

    <div class="form-group">
      <div class="col-xs-12 col-sm-offset-2 col-sm-10">
        {#if logs.history}
          <table class="table table-striped table-condensed" style="width: 100%; max-width:100%;">
            <thead>
              <tr>
                <th>Timestamp</th>
                <th>Method</th>
                <th>Path</th>
                <th class="text-right">Status</th>
              </tr>
            </thead>
            <tbody>
              {#each logs.history.items as msg}
                <tr>
                  <td>
                    <CgDate date={msg.timestamp} format={["shortDate", "mediumTime"]} utc={logs.timezone === "UTC"} />
                    <!-- svelte-ignore a11y-missing-attribute -->
                    <!-- svelte-ignore a11y-click-events-have-key-events -->
                    <!-- svelte-ignore a11y-no-static-element-interactions -->
                    <a on:click|preventDefault={cycle_timezone} class="text-muted">
                      {logs.timezone.toLowerCase()}
                    </a>
                  </td>
                  <td>{msg.method}</td>
                  <td>{msg.path}</td>
                  <td class="text-right" class:text-success={msg.status < 400} class:text-danger={msg.status >= 400}>
                    <strong>{msg.status}</strong>
                  </td>
                </tr>
              {/each}
            </tbody>
          </table>
        {/if}
      </div>
    </div>
  </form>
</div>
