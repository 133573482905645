<script context="module" lang="ts">
  import {ProxyConnection} from "$app/support/api/radsec/proxy_connection";
  import type {RadSecProxyConnection} from "$app/support/api/radsec/proxy_connections";
  import type {RadSecCodeCount} from "$app/support/api/radsec/stats";

  function sum_counts(kvps: RadSecCodeCount[]) {
    let total = 0;

    for (const kvp of kvps) {
      total += kvp.count;
    }

    return total;
  }
</script>

<script lang="ts">
  export let connection: RadSecProxyConnection;

  const details = new ProxyConnection({connection_id: connection.id});
  const loading = details.$loading;
  const error = details.$error;
  const stats = details.stats;

  let show_messages_sent = false;
  let show_messages_received = false;

  details.refresh().catch();
</script>

{#if !$loading && !$error}
  <div class="row">
    <div class="hidden-xs">
      <div class="col-xs-offset-4 col-sm-offset-3 col-md-offset-4 col-xs-8 col-sm-9 col-md-8 stack-2">
        <strong>Identification</strong>
      </div>

      <div class="col-xs-4 col-sm-3 col-md-4 text-right stack-1">
        <strong>IP</strong>
      </div>
      <div class="col-xs-8 col-sm-9 col-md-8 stack-1">
        <span>{connection.remote_addr}</span>
        <span class="glyphicon glyphicon-chevron-right" />
        <span>{connection.local_addr}</span>
      </div>

      <div class="col-xs-4 col-sm-3 col-md-4 text-right stack-1">
        <strong>Proxy To</strong>
      </div>
      <div class="col-xs-8 col-sm-9 col-md-8 stack-1">
        {#if connection.proxy_to}
          <span>{connection.proxy_to}</span>
        {/if}
      </div>
    </div>

    <div class="col-xs-offset-4 col-sm-offset-3 col-md-offset-4 col-xs-8 col-sm-9 col-md-8 stack-2">
      <strong>Statistics</strong>
    </div>

    <div class="col-xs-4 col-sm-3 col-md-4 text-right stack-1">
      <strong>Connected at</strong>
    </div>
    <div class="col-xs-8 col-sm-9 col-md-8 stack-1">
      <span>{$stats.connect_time}</span>
    </div>

    <div class="col-xs-4 col-sm-3 col-md-4 text-right stack-1">
      <strong>Messages</strong>
    </div>
    <div class="col-xs-8 col-sm-9 col-md-8">
      <div class="stack-1">
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <!-- svelte-ignore a11y-no-static-element-interactions -->
        <span
          on:click|preventDefault={() => {
            show_messages_sent = !show_messages_sent;
          }}
          class:active={show_messages_sent}
          class="btn btn-xs btn-default"
        >
          <span class="label label-primary">{sum_counts($stats.code_sent)}</span> sent
        </span>
      </div>

      {#if show_messages_sent}
        <div>
          {#if $stats.code_sent.length}
            <ul class="list-unstyled">
              {#each $stats.code_sent as sent}
                <li>
                  <span class="label label-primary">{sent.count}</span>
                  <code>{sent.code}</code>
                </li>
              {/each}
            </ul>
          {:else}
            <div>
              <span class="glyphicon glyphicon-info-sign" /> No sent messages.
            </div>
          {/if}
        </div>
      {/if}

      <div class="stack-1">
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <!-- svelte-ignore a11y-no-static-element-interactions -->
        <span
          on:click={() => {
            show_messages_received = !show_messages_received;
          }}
          class:active={show_messages_received}
          class="btn btn-xs btn-default"
        >
          <span class="label label-primary">{sum_counts($stats.code_received)}</span> received
        </span>
      </div>

      {#if show_messages_received}
        <div>
          {#if $stats.code_received.length}
            <ul class="list-unstyled">
              {#each $stats.code_received as received}
                <li>
                  <span class="label label-primary">{received.count}</span>
                  <code>{received.code}</code>
                </li>
              {/each}
            </ul>
          {:else}
            <div>
              <span class="glyphicon glyphicon-info-sign" /> No received messages.
            </div>
          {/if}
        </div>
      {/if}
    </div>

    <div class="col-xs-4 col-sm-3 col-md-4 text-right stack-1">
      <strong>Packets</strong>
    </div>
    <div class="col-xs-8 col-sm-9 col-md-8">
      <div class="stack-1">
        <span class="label label-primary">{$stats.packets_sent}</span> sent
      </div>
      <div class="stack-1">
        <span class="label label-primary">{$stats.packets_received}</span> received
      </div>
    </div>

    <div class="col-xs-4 col-sm-3 col-md-4 text-right stack-1">
      <strong>Bytes</strong>
    </div>
    <div class="col-xs-8 col-sm-9 col-md-8">
      <div class="stack-1"><span class="label label-primary">{$stats.bytes_sent}</span> sent</div>
      <div class="stack-1">
        <span class="label label-primary">{$stats.bytes_received}</span> received
      </div>
    </div>
  </div>
{/if}
