<script context="module" lang="ts">
  export type AlertType = "success" | "info" | "warning" | "danger";
  export type CloseFn = () => void;

  function update_alert_classes(alert_type: AlertType, close?: CloseFn) {
    const classes = ["alert", `alert-${alert_type}`];
    if (close) {
      classes.push("alert-dismissable");
    }
    return classes.join(" ");
  }
</script>

<script lang="ts">
  let alert_type: AlertType;
  export {alert_type as type};
  export let close: CloseFn | undefined = undefined;
  export let tag: string | undefined = undefined;

  $: alert_classes = update_alert_classes(alert_type, close);
</script>

<svelte:element this={tag ?? "div"} class={alert_classes}>
  {#if close}
    <button on:click|preventDefault={close} type="button" class="close" data-dismiss="alert" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  {/if}
  <slot />
</svelte:element>
