import {writable, type Readable, type Writable, derived} from "svelte/store";
import {Loadable} from "../loader";
import {cg_support_api, type DefaultApiTypes, type Params as BaseParams} from "../api";
import type {RadSecProxyConnectionFields} from "./proxy_connections";
import type {BaseRadSecStats, RadSecCodeCount} from "./stats";

export interface ProxyConnectionInfo extends RadSecProxyConnectionFields {}

export interface ProxyConnectionStats extends BaseRadSecStats {
  connect_time: string;
}

export interface ProxyConnectionStatsAlt
  extends Pick<
    ProxyConnectionStats,
    "bytes_sent" | "bytes_received" | "packets_sent" | "packets_received" | "connect_time"
  > {
  code_sent: RadSecCodeCount[];
  code_received: RadSecCodeCount[];
}

export interface Params {
  connection_id: string;
}

interface GetParams extends BaseParams, Params {}

interface ProxyConnectionDetails {
  info: ProxyConnectionInfo;
  stats: ProxyConnectionStats;
}

interface ProxyConnectionDetailsAlt {
  info: ProxyConnectionInfo;
  stats: ProxyConnectionStatsAlt;
}

interface ApiTypes extends DefaultApiTypes {
  get_result: ProxyConnectionDetails;
  get_params: GetParams;
}

export class ProxyConnection extends Loadable<ProxyConnectionDetails> {
  #params: Params;
  #details: Writable<ProxyConnectionDetailsAlt>;
  #info: Readable<ProxyConnectionInfo>;
  #stats: Readable<ProxyConnectionStatsAlt>;

  constructor(params: Params) {
    super();

    this.#params = {...params};
    this.#details = writable(default_details());
    this.#info = derived(this.#details, (details, set) => {
      set(details.info);
    });
    this.#stats = derived(this.#details, (details, set) => {
      set(details.stats);
    });
  }

  get info(): Readable<ProxyConnectionInfo> {
    return this.#info;
  }

  get stats(): Readable<ProxyConnectionStatsAlt> {
    return this.#stats;
  }

  async $load(): Promise<ProxyConnectionDetails> {
    return cg_support_api<ApiTypes>("/api/v1/radsec/proxy_connections/:connection_id").get({...this.#params});
  }

  $validate(data: ProxyConnectionDetails): boolean {
    return (
      typeof data === "object" &&
      data !== null &&
      typeof data.info === "object" &&
      data.info !== null &&
      typeof data.stats === "object" &&
      data.stats !== null
    );
  }

  $set(data: ProxyConnectionDetails): void {
    this.#details.set({
      info: data.info,
      stats: {
        ...data.stats,
        code_sent: Object.entries(data.stats.code_sent).map(([code, count]) => ({code, count})),
        code_received: Object.entries(data.stats.code_received).map(([code, count]) => ({code, count})),
      },
    });
  }

  $clear(): void {
    this.#details.set(default_details());
  }
}

function default_details(): ProxyConnectionDetailsAlt {
  return {
    info: {
      local_addr: "",
      remote_addr: "",
      proxy_to: "",
    },
    stats: {
      bytes_sent: 0,
      bytes_received: 0,
      packets_sent: 0,
      packets_received: 0,
      code_sent: [],
      code_received: [],
      connect_time: "",
    },
  };
}
