import {writable, type Readable, type Writable} from "svelte/store";
import {Loadable} from "../loader";
import {cg_support_api, type DefaultApiTypes} from "../api";

export interface BaseRadSecStats {
  [key: string]: number | {[code: string]: number} | string | boolean;
  bytes_sent: number;
  bytes_received: number;
  packets_sent: number;
  packets_received: number;
  code_sent: {[code: string]: number};
  code_received: {[code: string]: number};
}

export interface RadSecStats extends BaseRadSecStats {
  connection_errors: {[error_code: string]: number};
  connections_made: number;
  connections_lost: number;
}

export interface RadSecInstanceStats extends RadSecStats {
  ip: string;
}

export interface RadSecSummaryStats extends RadSecStats {
  $summary: true;
}

export type RadSecMixedStats = RadSecInstanceStats | RadSecSummaryStats;

export interface RadSecCodeCount {
  code: string;
  count: number;
}

export interface RadSecStatsAlt
  extends Pick<
    RadSecStats,
    "bytes_sent" | "bytes_received" | "packets_sent" | "packets_received" | "connections_made" | "connections_lost"
  > {
  code_sent: RadSecCodeCount[];
  code_received: RadSecCodeCount[];
  connection_errors: RadSecCodeCount[];
  ip?: RadSecInstanceStats["ip"];
  $summary?: RadSecSummaryStats["$summary"];
}

interface RadSecStatsResult {
  items: {[instance_ip: string]: RadSecStats};
}

interface ApiTypes extends DefaultApiTypes {
  get_result: RadSecStatsResult;
}

export type StatisticsSource = "stats" | "proxy_stats";

export class Statistics extends Loadable<RadSecStatsResult> {
  #path: string;
  #instances: Writable<RadSecStatsAlt[]>;

  constructor(path: StatisticsSource) {
    super();

    this.#path = path;
    this.#instances = writable([]);
  }

  get instances(): Readable<RadSecStatsAlt[]> {
    return this.#instances;
  }

  async $load(): Promise<RadSecStatsResult> {
    return cg_support_api<ApiTypes>("/api/v1/radsec/" + this.#path).get();
  }

  $validate(data: RadSecStatsResult): boolean {
    return typeof data === "object" && data !== null && typeof data.items === "object" && data.items !== null;
  }

  $set(data: RadSecStatsResult): void {
    const instances: RadSecInstanceStats[] = Object.entries(data.items).map(([ip, stats]) => ({ip, ...stats}));
    const instances_alt: RadSecStatsAlt[] = [summarise(instances), ...instances].map((instance) => {
      return {
        ...instance,
        code_sent: Object.entries(instance.code_sent).map(([code, count]) => ({code, count})),
        code_received: Object.entries(instance.code_received).map(([code, count]) => ({code, count})),
        connection_errors: Object.entries(instance.connection_errors).map(([code, count]) => ({code, count})),
      };
    });

    this.#instances.set(instances_alt);
  }

  $clear(): void {
    this.#instances.set([]);
  }
}

function summarise(instances: RadSecInstanceStats[]): RadSecSummaryStats {
  const summary: RadSecSummaryStats = {
    $summary: true,
    connection_errors: {},
    connections_made: 0,
    connections_lost: 0,
    bytes_sent: 0,
    bytes_received: 0,
    packets_sent: 0,
    packets_received: 0,
    code_sent: {},
    code_received: {},
  };

  for (const instance of instances) {
    for (const [key, stat] of Object.entries(instance)) {
      if (typeof stat === "number") {
        (summary[key] as number) += stat;
      } else if (typeof stat === "object") {
        const summary_stat = summary[key] as {[code: string]: number};
        for (const [subkey, value] of Object.entries(stat)) {
          if (!(subkey in summary_stat)) {
            summary_stat[subkey] = 0;
          }
          summary_stat[subkey] += value;
        }
      }
    }
  }

  return summary;
}
