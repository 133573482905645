import {writable, type Readable, type Writable} from "svelte/store";
import {Loadable} from "../loader";
import {cg_support_api, type DefaultApiTypes, type Params as BaseParams} from "../api";

export interface RadSecConnectionFields {
  local_addr: string;
  remote_addr: string;
  nas_id: string;
  mac_address: string;
  cert_serial: string;
}

export interface RadSecConnection extends RadSecConnectionFields {
  id: string;
}

export interface Params {
  instance?: string;
  mac_address?: string;
  nas_id?: string;
}

export function params_equal(a: Params, b: Params): boolean {
  return a.instance === b.instance && a.mac_address === b.mac_address && a.nas_id === b.nas_id;
}

interface GetParams extends BaseParams, Params {}

interface RadSecConnectionsResult {
  items: {[connection_id: string]: RadSecConnectionFields};
}

interface ApiTypes extends DefaultApiTypes {
  get_result: RadSecConnectionsResult;
  get_params: GetParams;
}

export class Connections extends Loadable<RadSecConnectionsResult> {
  #params: Params;
  #connections: Writable<RadSecConnection[]>;

  constructor() {
    super();

    this.#params = {};
    this.#connections = writable([]);
  }

  get connections(): Readable<RadSecConnection[]> {
    return this.#connections;
  }

  async $load(): Promise<RadSecConnectionsResult> {
    return cg_support_api<ApiTypes>("/api/v1/radsec/connections").get({...this.#params});
  }

  $validate(data: RadSecConnectionsResult): boolean {
    return typeof data === "object" && data !== null && typeof data.items === "object" && data.items !== null;
  }

  $set(data: RadSecConnectionsResult): void {
    const connections: RadSecConnection[] = Object.entries(data.items).map(([id, fields]) => ({id, ...fields}));

    this.#connections.set(connections);
  }

  $clear(): void {
    this.#connections.set([]);
  }

  set_instance(instance?: string) {
    if (instance) {
      this.#params.instance = instance;
    } else {
      delete this.#params.instance;
    }
  }

  set_mac_address(mac_address?: string) {
    if (mac_address) {
      this.#params.mac_address = mac_address;
    } else {
      delete this.#params.mac_address;
    }
  }

  set_nas_id(nas_id?: string) {
    if (nas_id) {
      this.#params.nas_id = nas_id;
    } else {
      delete this.#params.nas_id;
    }
  }
}
