<script context="module" lang="ts">
  import {require_system_health_status} from "./system_health/context";
  import type {SystemHealthStatus} from "./system_health/context";
  import {onDestroy} from "svelte";

  import aruba from "$assets/aruba-favicon_32x32.png";
  import loading from "$assets/support/loading-animated-gif-32x32.gif";
  import green_circle from "$assets/support/circle_32x32_green.png";
  import green_stale_circle from "$assets/support/circle_32x32_green_stale.png";
  import red_circle from "$assets/support/circle_32x32_red.png";

  function update(status: SystemHealthStatus, expired: boolean) {
    switch (status) {
      case "loading":
        return loading;
      case "pass":
        return expired ? green_stale_circle : green_circle;
      case "error":
      case "fail":
        return red_circle;
      case "unknown":
      case "normal":
      default:
        return aruba;
    }
  }
</script>

<script lang="ts">
  const status = require_system_health_status();
  let timer: ReturnType<typeof setTimeout> | undefined = undefined;
  let expired = false;

  $: if ($status === "pass" && !timer) {
    expired = false;
    timer = setTimeout(() => {
      expired = true;
    }, 60 * 60 * 1000);
  } else if ($status !== "pass" && timer) {
    clearTimeout(timer);
    timer = undefined;
  }
  $: favicon = update($status, expired);

  onDestroy(() => {
    if (timer) {
      clearTimeout(timer);
    }
  });
</script>

<svelte:head>
  <link href={favicon} rel="icon" />
</svelte:head>
