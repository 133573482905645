<div class="row">
  <div class="col-xs-12 col-sm-12">
    <hr class="stack-1" />
  </div>
</div>

<div class="visible-lg-block row small text-uppercase">
  <div class="col-lg-1">Status</div>
  <div class="col-lg-1">Result</div>
  <div class="col-lg-1">Args</div>
  <div class="col-lg-1">Kwargs</div>
  <div class="col-lg-3">Started</div>
  <div class="col-lg-3">Finished</div>
</div>

<div class="visible-lg-block row">
  <div class="col-xs-12">
    <hr class="stack-1" />
  </div>
</div>
