import {cg_support_api, type DefaultApiTypes, type Params} from "../api";

interface ApiTypes extends DefaultApiTypes {
  get_result: string;
}

export function system_version() {
  return cg_support_api<ApiTypes>("/version")
    .get()
    .then((data) => {
      if (typeof data === "string") {
        return data;
      } else {
        throw new TypeError(`system version: invalid type: ${typeof data}`);
      }
    });
}
