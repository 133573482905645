import {cg_support_api, type DefaultApiTypes} from "../api";

export interface QueueStat {
  messages: number;
  ready: number;
  unacknowledged: number;
}

export interface QueuesList {
  items: {[queue_name: string]: QueueStat};
}

interface ApiTypes extends DefaultApiTypes {
  get_result: QueuesList;
}

export function rabbitmq_stats() {
  return cg_support_api<ApiTypes>("/api/v1/queues/rabbitmq_stats");
}
