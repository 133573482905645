<script lang="ts">
  import {sms_history} from "$app/support/api/messaging/sms_history";
  import type {SmsMessage, SmsHistoryResult} from "$app/support/api/messaging/sms_history";
  import Alert from "$lib/Alert.svelte";
  import CgDate from "$lib/CgDate.svelte";
  import Phone from "$lib/Phone.svelte";
  import Spinner from "$lib/spinkit/Spinner.svelte";

  interface ModifiedSmsMessage extends SmsMessage {
    elapsed_updated?: string;
    elapsed_sent?: string;
  }

  interface ModifiedSmsHistoryResult extends SmsHistoryResult {
    items: ModifiedSmsMessage[];
  }

  const sms = {
    local_phone: "",
    phone: "",
    scope: "",
    status: "ready" as "ready" | "collecting" | "success" | "failure",
    history: undefined as ModifiedSmsHistoryResult | undefined,
    timezone: "local" as "local" | "UTC",
    timezones: ["local", "UTC"] as ("local" | "UTC")[],
  };

  function generateTimeUnitsStr(msecs: number) {
    if (msecs < 1000) {
      return "0";
    } else if (msecs < 1000 * 60) {
      return (msecs / 1000).toFixed(1) + "s";
    } else if (msecs < 1000 * 60 * 60) {
      return (msecs / 1000 / 60).toFixed(1) + "min";
    } else if (msecs < 1000 * 60 * 60 * 24) {
      return (msecs / 1000 / 60 / 60).toFixed(1) + "h";
    } else {
      return (msecs / 1000 / 60 / 60 / 24).toFixed(1) + "d";
    }
  }

  function collect() {
    sms.status = "collecting";
    sms.history = undefined;
    sms_history()
      .post(undefined, {
        phone_number: sms.phone,
        scope: sms.scope === "" ? undefined : sms.scope,
      })
      .then(
        function (data: ModifiedSmsHistoryResult) {
          sms.status = "success";
          for (const msg of data.items) {
            const date_created = Date.parse(msg.date_created);
            const date_updated = Date.parse(msg.date_updated);
            const date_sent = Date.parse(msg.date_sent);
            msg.elapsed_updated = generateTimeUnitsStr(date_updated - date_created);
            msg.elapsed_sent = generateTimeUnitsStr(date_sent - date_created);
          }
          sms.history = data;
          return data;
        },
        function (reason) {
          sms.status = "failure";
          return Promise.reject(reason);
        }
      );
  }

  function acknowledge() {
    sms.status = "ready";
  }

  function reset_form() {
    sms.status = "ready";
    sms.phone = "";
    clear_history();
  }

  function clear_history() {
    sms.status = "ready";
    sms.history = undefined;
  }

  $: can_collect = !!sms.phone;
</script>

<div>
  <div class="container">
    <div class="row">
      <div class="col-sm-offset-2 col-sm-10">
        <h1>SMS History</h1>
      </div>
    </div>

    <form class="form-horizontal" name="sms_history_form">
      <div class="form-group">
        <!-- svelte-ignore a11y-label-has-associated-control -->
        <label class="col-sm-2 control-label">Timezone</label>
        <div class="col-sm-4">
          <select bind:value={sms.timezone} class="form-control">
            {#each sms.timezones as tz}
              <option value={tz}>{tz}</option>
            {/each}
          </select>
          <p class="help-block">
            Time data will be displayed in
            <strong>{sms.timezone}</strong> timezone.
          </p>
        </div>
      </div>

      <div class="form-group">
        <!-- svelte-ignore a11y-label-has-associated-control -->
        <label class="col-sm-2 control-label">Scope</label>
        <div class="col-sm-4">
          <input
            bind:value={sms.scope}
            on:change={clear_history}
            name="email_dest_address"
            class="form-control"
            required
          />
          <p class="help-block">Scope that is related to this SMS.</p>
        </div>
      </div>

      <div class="form-group">
        <!-- svelte-ignore a11y-label-has-associated-control -->
        <label class="col-sm-2 control-label">Destination Phone</label>
        <div class="col-sm-8">
          <Phone bind:international_number={sms.phone} on:change={clear_history} class="form-control" required />
          {#if !sms.phone || sms.status != "success"}
            <p class="help-block">Enter the destination phone number to retrieve the SMS message history for.</p>
          {/if}
          {#if sms.phone && sms.status != "success"}
            <p class="help-block">
              The SMS message history will be retrieved for the
              <strong>international</strong>
              number
              <strong>{sms.phone}</strong>.
            </p>
          {/if}
          {#if sms.status == "success"}
            <p class="help-block">
              SUCCESS: Collected
              <strong>{sms.history?.items.length || 0}</strong> records of SMS messages sent to the
              <strong>international</strong>
              number
              <strong>{sms.phone}</strong>.
            </p>
          {/if}
        </div>
      </div>

      <div class="form-group">
        <div class="col-sm-offset-2 col-sm-8">
          {#if sms.status == "ready"}
            <button on:click|preventDefault={collect} disabled={!can_collect} class="btn btn-lg btn-primary">
              Collect
            </button>
          {/if}
          {#if sms.status == "collecting"}
            <button disabled class="btn btn-lg btn-primary">
              Collecting
              <Spinner type="circle-fade" />
            </button>
          {/if}
          {#if sms.status == "success"}
            <button on:click|preventDefault={reset_form} class="btn btn-lg btn-primary">Clear</button>
          {/if}
          {#if sms.status == "failure"}
            <Alert type="danger" close={acknowledge}>Failed to collect the message history</Alert>
          {/if}
        </div>
      </div>

      {#if sms.history}
        <table class="col-sm-offset-2 col-sm-8 table table-striped table-condensed">
          <thead>
            <tr>
              <th class="text-center">Created</th>
              <th class="text-center">SID</th>
              <th class="text-center">Sent</th>
              <th class="text-center">Updated</th>
              <th class="text-center">To</th>
              <th class="text-center">Status</th>
              <th class="text-left">Error</th>
            </tr>
          </thead>
          <tbody>
            {#each sms.history.items as msg}
              <tr>
                <td class="text-center">
                  <CgDate date={msg.date_created} format="short" utc={sms.timezone == "UTC"} />
                </td>
                <td class="text-center">{msg.sid}</td>
                <td class="text-center">+{msg.elapsed_sent}</td>
                <td class="text-center">+{msg.elapsed_updated}</td>
                <td class="text-center">{msg.to}</td>
                <td
                  class="text-center"
                  class:text-success={msg.status == "delivered"}
                  class:text-danger={msg.status == "undelivered" || msg.status == "failed"}
                >
                  <strong>{msg.status}</strong>
                </td>
                <td class="text-left">
                  {msg.error_code ? "ERROR " + msg.error_code + ": " + msg.error_message : ""}
                </td>
              </tr>
            {/each}
          </tbody>
        </table>
      {/if}
    </form>
  </div>
</div>
