<script context="module" lang="ts">
  import {RadSec} from "$app/support/api/radsec";
  import type {RadSecCodeCount, RadSecStatsAlt, StatisticsSource} from "$app/support/api/radsec/stats";
  import {writable} from "svelte/store";

  interface InstanceOptions {
    show_connections_current: boolean;
    show_connection_errors: boolean;
    show_messages_sent: boolean;
    show_messages_received: boolean;
  }

  interface InstancesOptions {
    [key: string]: InstanceOptions;
  }

  function sum_counts(kvps: RadSecCodeCount[]) {
    let total = 0;

    for (const kvp of kvps) {
      total += kvp.count;
    }

    return total;
  }

  function order_ips(ips: string[]): string[] {
    // Copy then sort
    ips = [...ips];
    ips.sort();
    return ips;
  }

  function order_instances(instances: RadSecStatsAlt[]): RadSecStatsAlt[] {
    // Copy then sort
    instances = [...instances];
    instances.sort((a, b) => {
      return a.$summary && !b.$summary
        ? -1
        : !a.$summary && b.$summary
        ? 1
        : a.ip && b.ip
        ? a.ip < b.ip
          ? -1
          : a.ip === b.ip
          ? 0
          : 1
        : 0;
    });
    return instances;
  }
</script>

<script lang="ts">
  export let source: StatisticsSource;

  const instance_options = writable<InstancesOptions>({});
  const radsec = new RadSec();
  const instances = radsec[source].instances;

  function init_options_for_instance(instance: RadSecStatsAlt): string {
    const key = instance.$summary ? "$summary" : (instance.ip as string);

    if (!$instance_options[key]) {
      $instance_options[key] = {
        show_connections_current: false,
        show_connection_errors: false,
        show_messages_sent: false,
        show_messages_received: false,
      };
    }

    return key;
  }

  function refresh() {
    radsec.instances.refresh();
    radsec[source].refresh();
  }

  $: page_title = source === "proxy_stats" ? "RadSec Proxy Statistics" : "RadSec Statistics";

  refresh();
</script>

<div class="container">
  <!-- PAGE TITLE -->
  <div class="row">
    <div class="col-xs-12">
      <h1>{page_title}</h1>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12 text-right stack-2">
      <form class="form-inline form-inline-xs">
        <div class="form-group">
          <button on:click|preventDefault={refresh} class="btn btn-default">
            <span class="glyphicon glyphicon-refresh" />
            <span class="hidden-xs">Refresh</span>
          </button>
        </div>
      </form>
    </div>
  </div>

  <!-- INSTANCE STATISTICS -->
  <div class="row hidden-xs stack-2">
    <div class="col-sm-4 col-md-3">
      <strong>Connections</strong>
    </div>
    <div class="col-sm-4 col-md-3">
      <strong>Messages</strong>
    </div>
    <div class="col-sm-2 col-md-2">
      <strong>Packets</strong>
    </div>
    <div class="col-sm-2 col-md-2">
      <strong>Bytes</strong>
    </div>
  </div>
  {#each order_instances($instances) as instance}
    {@const connections_current = instance.connections_made - instance.connections_lost}
    {@const ikey = init_options_for_instance(instance)}
    <div class="row">
      <!-- Instance title -->
      <div class="col-xs-12 col-sm-12 stack-1">
        {#if instance.$summary}
          <strong>All instances</strong>
        {:else}
          <strong>{instance.ip}</strong>
        {/if}
      </div>

      <!-- Connections -->
      <div class="col-xs-4 visible-xs stack-1 text-right">
        <span>Connections</span>
      </div>
      <div class="col-xs-8 col-sm-4 col-md-3">
        <div class="stack-1">
          <!-- svelte-ignore a11y-click-events-have-key-events -->
          <!-- svelte-ignore a11y-no-static-element-interactions -->
          <span
            on:click|preventDefault={() => {
              $instance_options[ikey].show_connections_current = !$instance_options[ikey].show_connections_current;
            }}
            class:active={$instance_options[ikey].show_connections_current}
            class="btn btn-lg btn-default"
          >
            <span class="label label-primary">{connections_current}</span>
            <span>current</span>
          </span>
        </div>
        {#if $instance_options[ikey].show_connections_current}
          <ul class="list-unstyled">
            <li>
              <span class="label label-success">{instance.connections_made}</span>
              <span>opened</span>
            </li>
            <li>
              <span class="label label-warning">{instance.connections_lost}</span>
              <span>closed</span>
            </li>
          </ul>
        {/if}
        <div class="stack-1">
          <!-- svelte-ignore a11y-click-events-have-key-events -->
          <!-- svelte-ignore a11y-no-static-element-interactions -->
          <span
            on:click|preventDefault={() => {
              $instance_options[ikey].show_connection_errors = !$instance_options[ikey].show_connection_errors;
            }}
            class:active={$instance_options[ikey].show_connection_errors}
            class="btn btn-xs btn-default"
          >
            <span class="label label-danger">{sum_counts(instance.connection_errors)}</span>
            <span>errors</span>
          </span>
        </div>
        {#if $instance_options[ikey].show_connection_errors}
          <div>
            {#if instance.connection_errors.length}
              <ul class="list-unstyled">
                {#each instance.connection_errors as error}
                  <li>
                    <span class="label label-danger">{error.count}</span>
                    <code>{error.code}</code>
                  </li>
                {/each}
              </ul>
            {/if}
            {#if !instance.connection_errors.length}
              <div>
                <span class="glyphicon glyphicon-info-sign" /> No connection errors.
              </div>
            {/if}
          </div>
        {/if}
      </div>

      <!-- Messages -->
      <div class="col-xs-4 visible-xs stack-1 text-right">Messages</div>
      <div class="col-xs-8 col-sm-4 col-md-3">
        <div class="stack-1">
          <!-- svelte-ignore a11y-click-events-have-key-events -->
          <!-- svelte-ignore a11y-no-static-element-interactions -->
          <span
            on:click|preventDefault={() => {
              $instance_options[ikey].show_messages_sent = !$instance_options[ikey].show_messages_sent;
            }}
            class:active={$instance_options[ikey].show_messages_sent}
            class="btn btn-xs btn-default"
          >
            <span class="label label-primary">{sum_counts(instance.code_sent)}</span> sent
          </span>
        </div>

        {#if $instance_options[ikey].show_messages_sent}
          <div>
            {#if instance.code_sent.length}
              <ul class="list-unstyled">
                {#each instance.code_sent as sent}
                  <li>
                    <span class="label label-primary">{sent.count}</span>
                    <code>{sent.code}</code>
                  </li>
                {/each}
              </ul>
            {/if}
            {#if !instance.code_sent.length}
              <div>
                <span class="glyphicon glyphicon-info-sign" /> No sent messages.
              </div>
            {/if}
          </div>
        {/if}

        <div class="stack-1">
          <!-- svelte-ignore a11y-click-events-have-key-events -->
          <!-- svelte-ignore a11y-no-static-element-interactions -->
          <span
            on:click|preventDefault={() => {
              $instance_options[ikey].show_messages_received = !$instance_options[ikey].show_messages_received;
            }}
            class:active={$instance_options[ikey].show_messages_received}
            class="btn btn-xs btn-default"
          >
            <span class="label label-primary">{sum_counts(instance.code_received)}</span> received
          </span>
        </div>

        {#if $instance_options[ikey].show_messages_received}
          <div>
            {#if instance.code_received.length}
              <ul class="list-unstyled">
                {#each instance.code_received as received}
                  <li>
                    <span class="label label-primary">{received.count}</span>
                    <code>{received.code}</code>
                  </li>
                {/each}
              </ul>
            {/if}
            {#if !instance.code_received.length}
              <div>
                <span class="glyphicon glyphicon-info-sign" /> No received messages.
              </div>
            {/if}
          </div>
        {/if}
      </div>

      <!-- Packets -->
      <div class="col-xs-4 visible-xs stack-1 text-right">Packets</div>
      <div class="col-xs-8 col-sm-2 col-md-2">
        <div class="stack-1"><span class="label label-primary">{instance.packets_sent}</span> sent</div>
        <div class="stack-1">
          <span class="label label-primary">{instance.packets_received}</span> received
        </div>
      </div>

      <!-- Bytes -->
      <div class="col-xs-4 visible-xs stack-1 text-right">Bytes</div>
      <div class="col-xs-8 col-sm-2 col-md-2">
        <div class="stack-1"><span class="label label-primary">{instance.bytes_sent}</span> sent</div>
        <div class="stack-1">
          <span class="label label-primary">{instance.bytes_received}</span> received
        </div>
      </div>
    </div>
  {/each}
</div>
