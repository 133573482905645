<script lang="ts">
  import {client_mac_addresses} from "$app/support/api/elasticsearch/client_mac_addresses";
  import type {MacAddressLog, MacAddressLogResult} from "$app/support/api/elasticsearch/client_mac_addresses";
  import Alert from "$lib/Alert.svelte";
  import CgDate from "$lib/CgDate.svelte";
  import Data from "$lib/Data.svelte";
  import Spinner from "$lib/spinkit/Spinner.svelte";

  interface ModifiedMacAddressLog extends MacAddressLog {
    $expand?: boolean;
    $show_log?: boolean;
  }

  interface ModifiedMacAddressLogResult extends MacAddressLogResult {
    items: ModifiedMacAddressLog[];
  }

  let logs = {
    mac: "",
    cursor: undefined as number | undefined,
    status: "ready" as "ready" | "collecting" | "success" | "failure",
    history: undefined as undefined | ModifiedMacAddressLogResult,
    timezone: "local" as "local" | "UTC",
    timezones: ["local", "UTC"] as ("local" | "UTC")[],
  };
  let layout: "table" | "list" = "table";

  function cycle_timezone() {
    const index = logs.timezones.indexOf(logs.timezone);
    if (index >= 0) {
      const next = (index + 1) % logs.timezones.length;
      logs.timezone = logs.timezones[next];
    }
  }

  function set_layout(new_layout: typeof layout) {
    if (layout !== new_layout && new_layout === "table" && logs.history) {
      for (const msg of logs.history.items) {
        msg.$expand = false;
        msg.$show_log = false;
      }
      logs = logs;
    }
    layout = new_layout;
  }

  function toggle_expand(msg: ModifiedMacAddressLog) {
    msg.$expand = !msg.$expand;
    logs = logs;
  }

  function toggle_show_log(msg: ModifiedMacAddressLog) {
    msg.$show_log = !msg.$show_log;
    logs = logs;
  }

  function collect(next: boolean) {
    logs.status = "collecting";
    logs.history = undefined;
    client_mac_addresses(logs.mac, next ? logs.cursor : undefined).then(
      function (data: ModifiedMacAddressLogResult) {
        logs.status = "success";
        logs.history = data;
        logs.cursor = data.cursor;
        return data;
      },
      function (reason) {
        logs.status = "failure";
        return Promise.reject(reason);
      }
    );
  }

  function collect_first() {
    collect(false);
  }

  function collect_next() {
    collect(true);
  }

  function acknowledge() {
    logs.status = "ready";
  }

  function reset_form() {
    logs.status = "ready";
    logs.mac = "";
    clear_history();
  }

  function clear_history() {
    logs.status = "ready";
    logs.history = undefined;
  }

  $: can_collect = logs.mac !== "";
</script>

<div class="container">
  <div class="row">
    <div class="col-sm-offset-2 col-sm-10">
      <h1>Client MAC Address Logs</h1>
    </div>
  </div>

  <form class="form-horizontal" name="mac_address_logs_form">
    <div class="form-group">
      <!-- svelte-ignore a11y-label-has-associated-control -->
      <label class="col-sm-2 control-label">Timezone</label>
      <div class="col-sm-4">
        <select bind:value={logs.timezone} class="form-control">
          {#each logs.timezones as tz}
            <option value={tz}>{tz}</option>
          {/each}
        </select>
        <p class="help-block">
          Time data will be displayed in
          <strong>{logs.timezone}</strong> timezone.
        </p>
      </div>
    </div>

    <div class="form-group">
      <!-- svelte-ignore a11y-label-has-associated-control -->
      <label class="col-sm-2 control-label">Client MAC Address</label>
      <div class="col-sm-8">
        <input
          bind:value={logs.mac}
          on:change={clear_history}
          name="client_mac_address"
          class="form-control"
          required
          placeholder="e.g. AA-BB-CC-DD-EE-FF"
        />
        {#if !logs.mac}
          <p class="help-block">Enter the client MAC address to receive the logs for.</p>
        {/if}
        {#if logs.mac && logs.status != "success"}
          <p class="help-block">
            The logs will be retrieved for the client MAC address
            <strong>{logs.mac}</strong>.
          </p>
        {/if}
        {#if logs.status == "success"}
          <p class="help-block">
            SUCCESS: Collected
            <strong>{logs.history?.items.length ?? 0}</strong> log records for <strong>{logs.mac}</strong>.
          </p>
        {/if}
      </div>
    </div>

    <div class="form-group">
      <div class="col-sm-offset-2 col-sm-8">
        {#if logs.status == "ready"}
          <button on:click|preventDefault={collect_first} disabled={!can_collect} class="btn btn-lg btn-primary">
            Collect
          </button>
        {/if}
        {#if logs.status == "success"}
          <button on:click|preventDefault={collect_first} disabled={!can_collect} class="btn btn-lg btn-primary">
            <span class="glyphicon glyphicon-refresh" /> Refresh
          </button>
        {/if}
        {#if logs.status == "success" && logs.cursor !== null}
          <button on:click|preventDefault={collect_next} disabled={!can_collect} class="btn btn-lg btn-primary">
            <span class="glyphicon glyphicon-forward" /> Next
          </button>
        {/if}
        {#if logs.status == "collecting"}
          <button disabled class="btn btn-lg btn-primary">
            Collecting
            <Spinner type="circle-fade" />
          </button>
        {/if}
        {#if logs.status == "success"}
          <button on:click|preventDefault={reset_form} class="btn btn-lg btn-default"> Clear </button>
        {/if}
        {#if logs.status == "failure"}
          <Alert type="danger" close={acknowledge}>Failed to collect the MAC address logs</Alert>
        {/if}
      </div>
    </div>

    {#if logs.history}
      <div class="form-group">
        <div class="col-sm-offset-2 col-sm-10 text-right">
          <div class="btn-group">
            <button
              type="button"
              class="btn btn-default"
              class:active={layout === "table"}
              on:click|preventDefault={() => set_layout("table")}
            >
              <span class="glyphicon glyphicon-th" /> Summary Table
            </button>
            <button
              type="button"
              class="btn btn-default"
              class:active={layout === "list"}
              on:click|preventDefault={() => set_layout("list")}
            >
              <span class="glyphicon glyphicon glyphicon-align-justify" /> Detailed List
            </button>
          </div>
        </div>
      </div>

      {#if layout === "table"}
        <div class="row hidden-xs">
          <div class="col-sm-2"><strong>Timestamp</strong></div>
          <div class="col-sm-3"><strong>Diagnostic</strong></div>
          <div class="col-sm-3"><strong>Username</strong></div>
          <div class="col-sm-2 col-md-3"><strong>NAS ID</strong></div>
          <div class="col-sm-2 col-md-1" />
        </div>
      {/if}

      {#each logs.history.items as msg}
        {@const list = layout === "list"}
        {@const table = layout === "table"}
        {@const detail = list || msg.$expand}
        {@const summary = table && !msg.$expand}
        <div class="row">
          <div class="col-xs-12 stack-1">
            <!-- 
              Reduce the margin for HR elements as we use them as item
              separators here.
            -->
            <hr style="margin-top: 0px; margin-bottom: 0px;" />
          </div>

          <div
            class:col-xs-9={table}
            class:col-xs-12={list}
            class:col-sm-offset-2={detail}
            class:col-sm-2={summary}
            class:col-sm-8={detail && table}
            class:col-sm-10={detail && list}
            class:col-md-9={detail && table}
            class:col-md-11={detail && list}
          >
            <strong>
              <CgDate date={msg.timestamp} format={["shortDate", "mediumTime"]} utc={logs.timezone === "UTC"} />
            </strong>
            <!-- svelte-ignore a11y-missing-attribute -->
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <!-- svelte-ignore a11y-no-static-element-interactions -->
            <a on:click|preventDefault={cycle_timezone} class="text-muted" class:visible-xs-inline={summary}>
              {logs.timezone.toLowerCase()}
            </a>
          </div>

          {#if table}
            <div class="col-xs-3 col-sm-2 col-md-1 text-right" class:visible-xs-block={summary}>
              <button on:click|preventDefault={() => toggle_expand(msg)} type="button" class="btn btn-xs btn-default">
                {#if !msg.$expand}
                  <span class="glyphicon glyphicon-expand" /> more
                {:else}
                  <span class="glyphicon glyphicon-collapse-up" /> less
                {/if}
              </button>
            </div>
          {/if}

          <div class={detail ? "col-xs-12 stack-1" : "col-xs-12 col-sm-3"}>
            <div class="row">
              {#if detail}
                <div class="col-xs-12 col-sm-2 text-right-sm text-muted">diagnostic</div>
              {/if}
              <div
                class="col-xs-12"
                class:col-sm-10={detail}
                class:text-overflow-sm-lg={summary}
                class:text-danger={summary && !!msg.error}
              >
                <Data value={msg.diagnostic} />
              </div>
            </div>
          </div>

          {#if detail && msg.error}
            <div class="col-xs-12 stack-1">
              <div class="row">
                <div class="col-xs-12 col-sm-2 text-right-sm text-muted">error</div>
                <div class="col-xs-12 col-sm-10 text-danger">
                  <Data value={msg.error} />
                </div>
              </div>
            </div>
          {/if}

          {#if summary || msg.username || msg.nas_identifier || msg.called_station_id || msg.message}
            {#if detail}
              <div class="col-xs-12 col-sm-offset-2 col-sm-10 stack-1">
                <strong> RADIUS </strong>
              </div>
            {/if}

            {#if summary || msg.username}
              <div class={detail ? "col-xs-12 stack-1" : "col-xs-12 col-sm-3"}>
                <div class="row">
                  {#if detail}
                    <div class="col-xs-12 col-sm-2 text-right-sm text-muted">username</div>
                  {/if}
                  <div class="col-xs-12" class:col-sm-10={detail} class:text-overflow-sm-lg={summary}>
                    {#if summary && msg.username}
                      <span class="visible-xs-inline text-muted">username</span>
                    {/if}
                    <Data value={msg.username} noplaceholders />
                  </div>
                </div>
              </div>
            {/if}

            {#if summary || msg.nas_identifier}
              <div class={detail ? "col-xs-12 stack-1" : "col-xs-12 col-sm-2 col-md-3"}>
                <div class="row">
                  {#if detail}
                    <div class="col-xs-12 col-sm-2 text-right-sm text-muted">nas identifier</div>
                  {/if}
                  <div class="col-xs-12" class:col-sm-10={detail} class:text-overflow-sm-lg={summary}>
                    {#if summary && msg.nas_identifier}
                      <span class="visible-xs-inline text-muted">nasid</span>
                    {/if}
                    <Data value={msg.nas_identifier} noplaceholders />
                  </div>
                </div>
              </div>
            {/if}

            {#if summary}
              <div class="hidden-xs col-sm-2 col-md-1 text-right">
                <button on:click|preventDefault={() => toggle_expand(msg)} type="button" class="btn btn-xs btn-default">
                  {#if !msg.$expand}
                    <span class="glyphicon glyphicon-expand" /> more
                  {:else}
                    <span class="glyphicon glyphicon-collapse-up" /> less
                  {/if}
                </button>
              </div>
            {/if}

            {#if detail && msg.called_station_id}
              <div class="col-xs-12 stack-1">
                <div class="row">
                  <div class="col-xs-12 col-sm-2 text-right-sm text-muted">called station</div>
                  <div class="col-xs-12 col-sm-10">
                    <Data value={msg.called_station_id} noplaceholders />
                  </div>
                </div>
              </div>
            {/if}

            {#if detail && msg.message}
              <div class="col-xs-12 stack-1">
                <div class="row">
                  <div class="col-xs-12 col-sm-2 text-right-sm text-muted">message</div>
                  <div class="col-xs-12 col-sm-10">
                    <Data value={msg.message} noplaceholders parsejson />
                  </div>
                </div>
              </div>
            {/if}
          {/if}

          {#if detail && (msg.status || msg.method || msg.path)}
            <div class="col-xs-12 col-sm-offset-2 col-sm-10 stack-1">
              <strong> HTTP </strong>
            </div>

            {#if msg.status}
              <div
                class="col-xs-12 stack-1"
                class:text-success={msg.status < 400}
                class:text-danger={msg.status >= 400}
              >
                <div class="row">
                  <div class="col-xs-12 col-sm-2 text-right-sm text-muted">http status</div>
                  <div class="col-xs-12 col-sm-10">
                    <strong><Data value={msg.status} noplaceholders /></strong>
                  </div>
                </div>
              </div>
            {/if}

            {#if msg.method}
              <div class="col-xs-12 stack-1">
                <div class="row">
                  <div class="col-xs-12 col-sm-2 text-right-sm text-muted">http method</div>
                  <div class="col-xs-12 col-sm-10">
                    <Data value={msg.method} noplaceholders />
                  </div>
                </div>
              </div>
            {/if}

            {#if msg.path}
              <div class="col-xs-12 stack-1">
                <div class="row">
                  <div class="col-xs-12 col-sm-2 text-right-sm text-muted">http path</div>
                  <div class="col-xs-12 col-sm-10">
                    <Data value={msg.path} noplaceholders />
                  </div>
                </div>
              </div>
            {/if}
          {/if}

          {#if detail && msg.log}
            <div class="col-xs-12 col-sm-offset-2 col-sm-10 stack-1">
              <button on:click|preventDefault={() => toggle_show_log(msg)} class="btn btn-xs btn-default">
                {#if !msg.$show_log}
                  <span class="glyphicon glyphicon-expand" /> show log
                {:else}
                  <span class="glyphicon glyphicon-collapse-up" /> hide log
                {/if}
              </button>
            </div>

            {#if msg.$show_log}
              <div class="col-xs-12 col-sm-offset-2 col-sm-10 stack-1">
                <Data value={msg.log} />
              </div>
            {/if}
          {/if}
        </div>
      {/each}
    {/if}
  </form>
</div>
