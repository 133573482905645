import {writable, type Readable, type Writable} from "svelte/store";
import {Loadable} from "../loader";
import {cg_support_api, type DefaultApiTypes, type Params as BaseParams} from "../api";

export interface RadSecProxyConnectionFields {
  local_addr: string;
  remote_addr: string;
  proxy_to: string;
}

export interface RadSecProxyConnection extends RadSecProxyConnectionFields {
  id: string;
}

export interface Params {
  instance?: string;
  proxy_to?: string;
}

export function params_equal(a: Params, b: Params): boolean {
  return a.instance === b.instance && a.proxy_to === b.proxy_to;
}

interface GetParams extends BaseParams, Params {}

interface RadSecProxyConnectionsResult {
  items: {[connection_id: string]: RadSecProxyConnectionFields};
}

interface ApiTypes extends DefaultApiTypes {
  get_result: RadSecProxyConnectionsResult;
  get_params: GetParams;
}

export class ProxyConnections extends Loadable<RadSecProxyConnectionsResult> {
  #params: Params;
  #connections: Writable<RadSecProxyConnection[]>;

  constructor() {
    super();

    this.#params = {};
    this.#connections = writable([]);
  }

  get connections(): Readable<RadSecProxyConnection[]> {
    return this.#connections;
  }

  async $load(): Promise<RadSecProxyConnectionsResult> {
    return cg_support_api<ApiTypes>("/api/v1/radsec/proxy_connections").get({...this.#params});
  }

  $validate(data: RadSecProxyConnectionsResult): boolean {
    return typeof data === "object" && data !== null && typeof data.items === "object" && data.items !== null;
  }

  $set(data: RadSecProxyConnectionsResult): void {
    const connections: RadSecProxyConnection[] = Object.entries(data.items).map(([id, fields]) => ({id, ...fields}));

    this.#connections.set(connections);
  }

  $clear(): void {
    this.#connections.set([]);
  }

  set_instance(instance?: string) {
    if (instance) {
      this.#params.instance = instance;
    } else {
      delete this.#params.instance;
    }
  }

  set_proxy_to(proxy_to?: string) {
    if (proxy_to) {
      this.#params.proxy_to = proxy_to;
    } else {
      delete this.#params.proxy_to;
    }
  }
}
