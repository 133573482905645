import {cg_support_api, type DefaultApiTypes} from "../api";

export interface RadiusTraceConfig {
  id: string;
  nas_addr: string;
  certificate_subject: string;
  certificate_serial: string;
  nas_identifier: string;
  nas_mac: string;
  created_at: string;
  expires_at: string;
}

export interface CreateRadiusTraceConfig
  extends Omit<Partial<RadiusTraceConfig>, "id" | "created_at" | "expires_at">,
    Pick<RadiusTraceConfig, "expires_at"> {}

export interface RadiusTraceConfigResult {
  items: RadiusTraceConfig[];
  count: number;
}

interface ApiTypes extends DefaultApiTypes {
  get_params: {limit: number; calculate_count: boolean};
  get_result: RadiusTraceConfigResult;
  delete_params: {radiustraceconfig_id: string};
  post_data: CreateRadiusTraceConfig;
}

export function get_captures() {
  return cg_support_api<ApiTypes>("/api/v1/radius_trace").get({limit: 100, calculate_count: true});
}

export function create_capture(data: CreateRadiusTraceConfig) {
  return cg_support_api<ApiTypes>("/api/v1/radius_trace").post(undefined, data);
}

export function delete_capture(capture_id: string) {
  return cg_support_api<ApiTypes>("/api/v1/radius_trace/:radiustraceconfig_id").delete({
    radiustraceconfig_id: capture_id,
  });
}
