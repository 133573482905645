<script context="module" lang="ts">
  import Data from "$lib/Data.svelte";
  import {Redis} from "$support/api/services/redis";
  import type {Param} from "$support/api/services/redis";

  function update_params(params: Param[], filter: string) {
    return filter ? params.filter((param) => param.name.indexOf(filter) !== -1) : params;
  }
</script>

<script lang="ts">
  const redis = new Redis();
  const info = redis.info;
  let options = {
    filter: "",
  };

  function reset_filter() {
    options.filter = "";
  }

  redis.refresh();

  $: params = update_params($info, options.filter);
</script>

<div class="container">
  <div class="row">
    <div class="col-xs-12">
      <h1>Redis Information</h1>
    </div>
  </div>

  <div class="row stack-1">
    <div class="col-xs-12 text-right">
      <form class="form-inline form-inline-xs">
        <div class="form-group">
          <span>
            <div class="input-group">
              <input bind:value={options.filter} class="form-control" placeholder="Filter" />
              <span class="input-group-btn">
                <button on:click|preventDefault={reset_filter} disabled={!options.filter} class="btn btn-default">
                  <span class="glyphicon glyphicon-remove-circle" />
                  <span class="hidden-xs">Clear</span>
                </button>
              </span>
            </div>
          </span>
        </div>
        <div class="form-group">
          <button on:click|preventDefault={() => redis.refresh()} class="btn btn-default">
            <span class="glyphicon glyphicon-refresh" />
            <span class="hidden-xs">Refresh</span>
          </button>
        </div>
      </form>
    </div>
  </div>

  {#each params as param}
    <div class="row">
      <div class="col-sm-4 text-right-sm stack-1 text-muted">
        {param.name}
      </div>
      <div class="col-sm-8 stack-1">
        <Data value={param.value} />
      </div>
    </div>
  {/each}
</div>
