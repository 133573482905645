<script lang="ts">
  import type {Execution} from "$app/support/api/queues/tasks";
  import TaskExecField from "./TaskExecField.svelte";
  import TaskExecStatus from "./TaskExecStatus.svelte";

  export let exec: Execution;

  let show_detail = false;
</script>

<div class="row">
  <TaskExecField class="col-xs-12 col-lg-1" cell label="Status">
    <TaskExecStatus success={exec.success} />
  </TaskExecField>
  <TaskExecField class="col-xs-12 col-lg-1" cell label="Result" value={exec.result} />
  <TaskExecField class="col-xs-12 col-lg-1" cell overflow label="Args" value={exec.args} />
  <TaskExecField class="col-xs-12 col-lg-1" cell overflow label="Kwargs" value={exec.kwargs} />
  <TaskExecField class="col-xs-12 col-lg-3" cell label="Started" value={exec.started_at} />
  <TaskExecField class="col-xs-12 col-lg-3" cell label="Finished" value={exec.finished_at} />

  <div class="col-xs-offset-2 col-xs-10 col-lg-offset-0 col-lg-1">
    {#if show_detail}
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <!-- svelte-ignore a11y-no-static-element-interactions -->
      <span on:click|preventDefault={() => (show_detail = !show_detail)} class="btn btn-xs btn-default">
        <span class="glyphicon glyphicon-expand" /> more properties
      </span>
    {:else}
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <!-- svelte-ignore a11y-no-static-element-interactions -->
      <span on:click|preventDefault={() => (show_detail = !show_detail)} class="btn btn-xs btn-default">
        <span class="glyphicon glyphicon-collapse-up" /> less properties
      </span>
    {/if}
  </div>

  {#if show_detail}
    <div>
      <TaskExecField class="col-xs-12 visible-lg-block" label="Status">
        <TaskExecStatus success={exec.success} />
      </TaskExecField>
      <TaskExecField class="col-xs-12 visible-lg-block" label="Result" value={exec.result} />
      <TaskExecField class="col-xs-12 visible-lg-block" label="Args" value={exec.args} />
      <TaskExecField class="col-xs-12 visible-lg-block" label="Kwargs" value={exec.kwargs} />
      <TaskExecField class="col-xs-12 visible-lg-block" label="Started" value={exec.started_at} />
      <TaskExecField class="col-xs-12 visible-lg-block" label="Finished" value={exec.finished_at} />
      <TaskExecField class="col-xs-12" label="ID" value={exec.task_id} />
      <TaskExecField class="col-xs-12" label="Name" value={exec.task_name} />
      <TaskExecField class="col-xs-12" label="Queue" value={exec.$queue_name} />
      <TaskExecField class="col-xs-12" label="Role" value={exec.$role} />
      <TaskExecField class="col-xs-12" label="Instance" value={exec.$instance} />
    </div>
  {/if}
</div>
<div class="row">
  <div class="col-xs-12 col-lg-12">
    <hr class="stack-1" />
  </div>
</div>
