<script context="module" lang="ts">
  import {onDestroy} from "svelte";
  import {format_iso8601_interval} from "./date";

  export type RefreshFn = () => void;
  type Timer = ReturnType<typeof setInterval>;

  function update_labels(intervals: number[]): string[] {
    const labels: string[] = [];
    for (const interval of intervals) {
      labels.push(format_iso8601_interval(interval));
    }
    return labels;
  }

  function update_timer(handler: RefreshFn, auto_refresh: boolean, interval: number, timer?: Timer) {
    if (timer) {
      clearInterval(timer);
    }
    if (auto_refresh) {
      return setInterval(handler, interval * 1000);
    }
  }
</script>

<script lang="ts">
  export let auto_refresh: boolean | undefined = undefined;
  export let time_intervals: number[] | undefined = undefined;
  export let initial_interval: number | undefined = undefined;
  export let onrefresh: RefreshFn | undefined = undefined;

  let menu_open = false;
  let timer: Timer | undefined = undefined;
  let element: HTMLElement | undefined = undefined;

  function refresh() {
    close();
    trigger_refresh();

    // Reset timer on manual refresh (reactive statements will reenable if required)
    if (timer) {
      clearInterval(timer);
      timer = undefined;
    }
  }

  function trigger_refresh() {
    if (onrefresh) {
      onrefresh();
    }
  }

  function close() {
    menu_open = false;
  }

  function toggle() {
    menu_open = !menu_open;
  }

  function monitor_clicks(event: Event) {
    if (menu_open && element && event.target instanceof Node && !element.contains(event.target)) {
      // The user clicked somewhere outside this dropdown while it was open.
      menu_open = false;
    }
  }

  $: current_auto_refresh = auto_refresh ?? false;
  $: intervals = time_intervals && time_intervals.length > 0 ? time_intervals : [15, 60, 300, 900, 1800];
  $: initial =
    initial_interval !== undefined && intervals.indexOf(initial_interval) !== -1
      ? initial_interval
      : intervals[intervals.length - 1];
  $: current_interval = initial;
  $: timer = update_timer(trigger_refresh, current_auto_refresh, current_interval, timer);
  $: labels = update_labels(intervals);
  $: if (menu_open) {
    document.addEventListener("click", monitor_clicks);
  } else {
    document.removeEventListener("click", monitor_clicks);
  }

  onDestroy(() => {
    document.removeEventListener("click", monitor_clicks);
    if (timer) {
      clearInterval(timer);
    }
  });
</script>

<div bind:this={element} class="btn-group" class:open={menu_open}>
  <button on:click|preventDefault={refresh} class="btn btn-default">
    <span class="glyphicon glyphicon-refresh" />
    <span class="hidden-xs">Refresh</span>
  </button>
  <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown" on:click|preventDefault={toggle}>
    <span class="caret" />
  </button>
  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <ul class="dropdown-menu" role="menu" on:click={close}>
    <li class="indent">
      <div class="checkbox">
        <label>
          <input type="checkbox" bind:checked={current_auto_refresh} />
          Auto refresh
        </label>
      </div>
    </li>
    <li class="divider" />
    <li class="indent">Refresh interval</li>
    {#each intervals as interval, index}
      <li class="indent">
        <div class="radio">
          <label>
            <input type="radio" bind:group={current_interval} value={interval} />
            {labels[index]}
          </label>
        </div>
      </li>
    {/each}
  </ul>
</div>

<style>
  .indent {
    padding-left: 20px;
  }
</style>
