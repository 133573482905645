import {cg_support_api, type DefaultApiTypes} from "../api";

export type ServerIpAddress = string;

export interface HealthCheckResult {
  success: boolean;
  version: string;
  checks: {[check: string]: string | boolean | null};
}

interface ApiTypes extends DefaultApiTypes {
  get_result: HealthCheckResult;
}

export function service_health_api(role: string, instance: string) {
  return cg_support_api<ApiTypes>("/api/v1/services/roles/:role/:instance/health", {role, instance});
}
