import {get, writable, type Readable, type Writable, derived} from "svelte/store";
import {rabbitmq_stats, type QueueStat} from "./rabbitmq_stats";

export interface Queue {
  name: string;
  stats: QueueStat;
}

interface State {
  loading: boolean;
  error: boolean;
  queues: Queue[];
}

export class RabbitMQ {
  #state: Writable<State>;
  #loading: Readable<State["loading"]>;
  #error: Readable<State["error"]>;
  #queues: Readable<State["queues"]>;

  constructor() {
    this.#state = writable({loading: false, error: false, queues: []});
    this.#loading = derived(this.#state, (state) => state.loading);
    this.#error = derived(this.#state, (state) => state.error);
    this.#queues = derived(this.#state, (state) => state.queues);
  }

  get loading(): Readable<State["loading"]> {
    return this.#loading;
  }

  get error(): Readable<State["error"]> {
    return this.#error;
  }

  get queues(): Readable<State["queues"]> {
    return this.#queues;
  }

  refresh() {
    if (!get(this.#loading)) {
      this.#state.set({loading: true, error: false, queues: []});

      rabbitmq_stats()
        .get()
        .then(
          (data) => {
            const queues = Object.entries(data.items).map<Queue>(([name, stats]) => ({name, stats}));
            queues.sort((a, b) => (a.name < b.name ? -1 : a.name === b.name ? 0 : 1));
            this.#state.set({loading: false, error: false, queues: []});
          },
          (reason) => {
            this.#state.set({loading: false, error: true, queues: []});
          }
        );
    }
  }
}
