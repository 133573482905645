import {cg_support_api, type DefaultApiTypes, type Params} from "../api";

export interface MacAddressLog {
  timestamp: string;
  log: string;
  diagnostic: string;
  error: string;
  nas_identifier: string;
  called_station_id: string;
  message: string;
  username: string;
  method: string;
  status: number;
  path: string;
}

export interface MacAddressLogResult {
  items: MacAddressLog[];
  cursor: number;
}

interface GetParams extends Params {
  mac: string;
  cursor?: number;
}

interface ApiTypes extends DefaultApiTypes {
  get_result: MacAddressLogResult;
  get_params: GetParams;
}

export function client_mac_addresses(mac: string, cursor?: number) {
  return cg_support_api<ApiTypes>("/api/v1/elasticsearch/client_mac_addresses").get({mac, cursor});
}
