import {cg_support_api, type DefaultApiTypes, type Params} from "../api";

export interface ScopeForNasId {
  scope_path?: string | null;
  nas_identifier: string;
}

interface GetParams extends Params {
  nas_identifier: string;
}

interface ApiTypes extends DefaultApiTypes {
  get_result: ScopeForNasId;
  get_params: GetParams;
}

export function nas_get_scope_for_id(nas_identifier: string) {
  return cg_support_api<ApiTypes>("/api/v1/nas/scope_by_nas_identifier").get({nas_identifier});
}
