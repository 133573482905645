import {cg_support_api, type DefaultApiTypes} from "../api";

export interface TestSms {
  phone_number: string;
  scope?: string | null;
  use_queue?: boolean;
}

export interface SmsSendResult {
  sid?: string | null;
  error_msg?: string | null;
}

interface ApiTypes extends DefaultApiTypes {
  post_data: TestSms;
  post_result: SmsSendResult;
}

export function send_sms() {
  return cg_support_api<ApiTypes>("/api/v1/messaging/send_sms");
}
