<script context="module" lang="ts">
  import {Queues} from "$app/support/api/queues";
  import type {Queue} from "$app/support/api/queues/rabbitmq";
  import type {TaskStats} from "$app/support/api/queues/task_status_api";
  import type {Task} from "$app/support/api/queues/tasks";
  import Spinner from "$lib/spinkit/Spinner.svelte";
  import TaskExec from "./TaskExec.svelte";
  import TaskExecHeader from "./TaskExecHeader.svelte";

  interface ModifiedTask extends Task {
    $show_total?: boolean;
    $show_success?: boolean;
    $show_failure?: boolean;
    $show_running?: boolean;
  }

  function update_tasks(tasks: Task[], filter: string): ModifiedTask[] {
    const filtered = tasks.filter((task) => !filter || task.task_name.includes(filter));
    filtered.sort((a, b) => (a.task_name < b.task_name ? -1 : a.task_name === b.task_name ? 0 : 1));
    return filtered;
  }

  function update_queues(queues: Queue[], filter: string): Queue[] {
    return queues.filter((queue) => !filter || queue.name.includes(filter));
  }
</script>

<script lang="ts">
  const options = {
    filter: "",
  };

  const queues = new Queues();
  const tasks_store = queues.tasks.tasks;
  const tasks_loading = queues.tasks.loading;
  const rabbitmq_loading = queues.rabbitmq.loading;
  const queues_store = queues.rabbitmq.queues;

  function reset_filter() {
    options.filter = "";
  }

  function sum(obj: TaskStats) {
    var total = 0;

    Object.values(obj).forEach((value) => {
      total += value;
    });

    return total;
  }

  queues.refresh();

  $: tasks = update_tasks($tasks_store, options.filter);
  $: rabbitmq_queues = update_queues($queues_store, options.filter);
</script>

<div class="container">
  <div class="row">
    <div class="col-xs-12">
      <h1>Queues</h1>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12 text-right">
      <form class="form-inline form-inline-xs">
        <div class="form-group">
          <span>
            <div class="input-group">
              <input bind:value={options.filter} class="form-control" placeholder="Filter" />
              <span class="input-group-btn">
                <button on:click|preventDefault={reset_filter} disabled={!options.filter} class="btn btn-default">
                  <span class="glyphicon glyphicon-remove-circle" />
                  <span class="hidden-xs">Clear</span>
                </button>
              </span>
            </div>
          </span>
        </div>
        <div class="form-group">
          <button on:click|preventDefault={() => queues.refresh()} class="btn btn-default">
            <span class="glyphicon glyphicon-refresh" />
            <span class="hidden-xs">Refresh</span>
          </button>
        </div>
      </form>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12">
      <h2>
        Tasks {#if $tasks_loading}<small><Spinner type="circle-fade" /></small>{/if}
      </h2>
    </div>
  </div>

  <div class="row hidden-xs small text-uppercase">
    <div class="col-sm-6 stack-1">Name</div>
    <div class="col-sm-1 stack-1">Total</div>
    <div class="col-sm-1 stack-1">Success</div>
    <div class="col-sm-1 stack-1">Failure</div>
    <div class="col-sm-1 stack-1">Running</div>
  </div>

  <div class="row hidden-xs">
    <div class="col-xs-12">
      <hr class="stack-1" />
    </div>
  </div>

  {#each tasks as task}
    <div>
      <div class="row">
        <div class="col-xs-12 col-sm-6 stack-1">
          <strong>{task.task_name}</strong>
        </div>
        <div class="col-xs-2 visible-xs-block stack-1 text-right">
          <span class="label label-default">Total</span>
        </div>
        <div class="col-xs-10 col-sm-1 stack-1">
          <!-- svelte-ignore a11y-click-events-have-key-events -->
          <!-- svelte-ignore a11y-no-static-element-interactions -->
          <span
            on:click|preventDefault={() => {
              task.$show_total = task.$show_success = task.$show_failure = task.$show_running = !task.$show_total;
            }}
            class:active={task.$show_total}
            class="btn btn-xs btn-primary">{sum(task.summary)}</span
          >
        </div>
        <div class="col-xs-2 visible-xs-block stack-1 text-right">
          <span class="label label-default">Success</span>
        </div>
        <div class="col-xs-10 col-sm-1 stack-1">
          <!-- svelte-ignore a11y-click-events-have-key-events -->
          <!-- svelte-ignore a11y-no-static-element-interactions -->
          <span
            on:click|preventDefault={() => {
              task.$show_success = !task.$show_success;
            }}
            class:active={task.$show_success}
            class="btn btn-xs btn-success">{task.summary.success}</span
          >
        </div>
        <div class="col-xs-2 visible-xs-block stack-1 text-right">
          <span class="label label-default">Failure</span>
        </div>
        <div class="col-xs-10 col-sm-1 stack-1">
          <!-- svelte-ignore a11y-click-events-have-key-events -->
          <!-- svelte-ignore a11y-no-static-element-interactions -->
          <span
            on:click|preventDefault={() => {
              task.$show_failure = !task.$show_failure;
            }}
            class:active={task.$show_failure}
            class="btn btn-xs btn-danger">{task.summary.failure}</span
          >
        </div>
        <div class="col-xs-2 visible-xs-block stack-1 text-right">
          <span class="label label-default">Running</span>
        </div>
        <div class="col-xs-10 col-sm-1 stack-1">
          <!-- svelte-ignore a11y-click-events-have-key-events -->
          <!-- svelte-ignore a11y-no-static-element-interactions -->
          <span
            on:click={() => {
              task.$show_running = !task.$show_running;
            }}
            class:active={task.$show_running}
            class="btn btn-xs btn-warning">{task.summary.running}</span
          >
        </div>

        <div class="col-xs-offset-2 col-xs-10 col-sm-offset-0 col-sm-1">
          {#if !task.$show_total}
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <!-- svelte-ignore a11y-no-static-element-interactions -->
            <span
              on:click|preventDefault={() => {
                task.$show_total = task.$show_success = task.$show_failure = task.$show_running = !task.$show_total;
              }}
              class="btn btn-xs btn-default"
            >
              <span class="glyphicon glyphicon-expand" /> show detail
            </span>
          {/if}
          {#if task.$show_total}
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <!-- svelte-ignore a11y-no-static-element-interactions -->
            <span
              on:click|preventDefault={() => {
                task.$show_total = task.$show_success = task.$show_failure = task.$show_running = !task.$show_total;
              }}
              class="btn btn-xs btn-default"
            >
              <span class="glyphicon glyphicon-collapse-up" /> hide detail
            </span>
          {/if}
        </div>
      </div>

      {#if task.$show_total || task.$show_running}
        <div class="row">
          <div class="col-xs-12 stack-1 small">
            <span class="text-uppercase"> <span class="glyphicon glyphicon-tasks" /> Running Tasks </span>
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <!-- svelte-ignore a11y-no-static-element-interactions -->
            <span
              on:click|preventDefault={() => {
                task.$show_running = !task.$show_running;
              }}
              class:active={task.$show_running}
              class="btn btn-xs btn-warning"
            >
              <span>{task.summary.running}</span> running
            </span>
          </div>
        </div>

        {#if task.$show_running}
          <TaskExecHeader />
          {#each task.executing as exec}
            <TaskExec {exec} />
          {/each}
        {/if}
      {/if}

      {#if task.$show_total || task.$show_success || task.$show_failure}
        <div class="row">
          <div class="col-xs-12 stack-1 small">
            <span class="text-uppercase"> <span class="glyphicon glyphicon-tasks" /> Recent Completed Tasks </span>
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <!-- svelte-ignore a11y-no-static-element-interactions -->
            <span
              on:click|preventDefault={() => {
                task.$show_success = !task.$show_success;
              }}
              class:active={task.$show_success}
              class="btn btn-xs btn-success"
            >
              <span>{task.summary.running}</span> success
            </span>
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <!-- svelte-ignore a11y-no-static-element-interactions -->
            <span
              on:click|preventDefault={() => {
                task.$show_failure = !task.$show_failure;
              }}
              class:active={task.$show_failure}
              class="btn btn-xs btn-danger"
            >
              <span>{task.summary.failure}</span> failure
            </span>
          </div>
        </div>

        {#if task.$show_success || task.$show_failure}
          <TaskExecHeader />
          {#each task.history as exec}
            {#if (task.$show_success && exec.success) || (task.$show_failure && !exec.success)}
              <TaskExec {exec} />
            {/if}
          {/each}
        {/if}
      {/if}
    </div>
  {/each}

  <div class="row">
    <div class="col-xs-12">
      <h2>
        Active Queues {#if $rabbitmq_loading}<small><Spinner type="circle-fade" /></small>{/if}
      </h2>
    </div>
  </div>

  {#each rabbitmq_queues as queue}
    <div>
      <div class="row">
        <div class="col-xs-12 col-sm-6 col-md-6 stack-1">
          <strong>{queue.name}</strong>
        </div>
        <div class="col-xs-3 col-sm-2 col-md-1 stack-1">
          Tot. <span class="label label-primary">{queue.stats.messages}</span>
        </div>
        <div class="col-xs-3 col-sm-2 col-md-1 stack-1">
          Rdy. <span class="label label-warning">{queue.stats.messages}</span>
        </div>
        <div class="col-xs-3 col-sm-2 col-md-1 stack-1">
          Ack. <span class="label label-success">{queue.stats.messages}</span>
        </div>
      </div>
    </div>
  {/each}
</div>
