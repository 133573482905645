import {get, writable, type Readable, type Writable, derived} from "svelte/store";
import {task_status_api, type TaskStats, type TaskStatusResult} from "./task_status_api";
import {service_role_api} from "../services/service_role_api";
import {task_servers_api, type TaskServer} from "./task_servers_api";
import {task_history_api} from "./task_history_api";
import type {TaskExecution} from "./task_execution";

export interface Execution extends TaskExecution {
  $role?: string;
  $instance?: string;
  $queue_name?: string;
  $index?: number;
}

export interface Status extends TaskStatusResult {
  executing: Execution[];
}

export interface Server extends TaskServer {
  $history?: Execution[];
  $status?: Status;
}

export interface Instance {
  [queue_name: string]: Server;
}

export interface Role {
  [instance: string]: Instance;
}

export interface TaskServers {
  [role: string]: Role;
}

export interface Task {
  task_name: string;
  summary: TaskStats;
  servers: Server[];
  executing: Execution[];
  history: Execution[];
}

interface State {
  loading: boolean;
  error: boolean;
  tasks: Task[];
}

export class Tasks {
  #state: Writable<State>;
  #loading: Readable<boolean>;
  #error: Readable<boolean>;
  #tasks: Readable<Task[]>;

  constructor() {
    this.#state = writable({loading: false, error: false, tasks: []});
    this.#loading = derived(this.#state, (state) => state.loading);
    this.#error = derived(this.#state, (state) => state.error);
    this.#tasks = derived(this.#state, (state) => state.tasks);
  }

  get loading(): Readable<State["loading"]> {
    return this.#loading;
  }

  get error(): Readable<State["error"]> {
    return this.#error;
  }

  get tasks(): Readable<State["tasks"]> {
    return this.#tasks;
  }

  refresh() {
    const task_servers: TaskServers = {};

    if (!get(this.#loading)) {
      const roles = ["background", "radius", "support"]; // TODO: Is there an API from which this list can be retrieved.

      this.#state.set({loading: true, error: false, tasks: []});

      Promise.all(
        roles.map((role: string) => {
          task_servers[role] = {};
          // Retrieve all of the instances for each role.
          return service_role_api(role)
            .get()
            .then((data) => {
              var role_instances = data.items;
              return Promise.all(
                role_instances.map((instance) => {
                  // Retrieve the task servers running on each instance.
                  return task_servers_api(role, instance)
                    .get()
                    .then((data) => {
                      task_servers[role][instance] = data.items;
                      return Promise.all(
                        Object.entries(task_servers[role][instance]).map(([queue_name, task_server]) => {
                          return Promise.all([
                            // Retrieve the task status for each task server.
                            task_status_api(role, instance, queue_name)
                              .get()
                              .then((data) => {
                                data.executing.forEach((item: Execution, index) => {
                                  // Update each task with context information.
                                  item.$role = role;
                                  item.$instance = instance;
                                  item.$queue_name = queue_name;
                                  item.$index = index;
                                });
                                task_server.$status = data;
                              }),
                            // Retrieve the task history for each task server.
                            task_history_api(role, instance, queue_name)
                              .get()
                              .then((data) => {
                                data.items.forEach((item: Execution, index) => {
                                  // Update each task with context information.
                                  item.$role = role;
                                  item.$instance = instance;
                                  item.$queue_name = queue_name;
                                  item.$index = index;
                                });
                                task_server.$history = data.items;
                              }),
                          ]);
                        })
                      );
                    });
                })
              );
            });
        })
      ).then(
        () => {
          this.#state.set({loading: false, error: false, tasks: refresh_tasks(task_servers)});
        },
        () => {
          this.#state.set({loading: false, error: true, tasks: refresh_tasks(task_servers)});
        }
      );
    }
  }
}

function refresh_tasks(task_servers: TaskServers) {
  const tasks_by_name: {[task_name: string]: Task} = {};

  for (const instances of Object.values(task_servers)) {
    for (const queues of Object.values(instances)) {
      for (const task_server of Object.values(queues)) {
        for (const task_name of task_server.tasks) {
          // role, instance, queue_name, task_name
          const task = tasks_by_name[task_name] || {
            task_name: task_name,
            summary: {
              success: 0,
              failure: 0,
              running: 0,
            },
            servers: [],
            executing: [],
            history: [],
          };
          task.servers.push(task_server);
          if (task_server.$history) {
            for (const history of task_server.$history) {
              if (history.task_name === task_name) {
                task.history.push(history);
              }
            }
          }
          if (task_server.$status && task_server.$status.executing) {
            for (const executing of task_server.$status.executing) {
              if (executing.task_name === task_name) {
                task.executing.push(executing);
              }
            }
          }
          if (task_server.$status && task_server.$status.tasks && task_server.$status.tasks[task_name]) {
            task.summary.success += task_server.$status.tasks[task_name].success;
            task.summary.failure += task_server.$status.tasks[task_name].failure;
            task.summary.running += task_server.$status.tasks[task_name].running;
          }
          tasks_by_name[task_name] = task;
        }
      }
    }
  }

  return Object.values(tasks_by_name);
}
