<script lang="ts">
  import {nas_get_scope_for_id} from "$app/support/api/nas/nas_get_scope_for_id";

  const options = {
    customer_id: "",
    nas_id: "",
    additional_checks: "none" as "none" | "scope_path" | "central_id",
  };
  let status: "init" | "running" | "done" | "error" = "init";
  let scope_found: boolean | undefined = undefined;
  let scope_matched: boolean | undefined = undefined;
  let actual_customer_id: string | undefined = undefined;

  function check_nas_id() {
    const params = {...options};

    status = "running";
    scope_found = false;
    scope_matched = false;

    nas_get_scope_for_id(params.nas_id).then(
      function (data) {
        status = "done";
        actual_customer_id = data.scope_path ?? undefined;
        if (data.scope_path) {
          scope_found = true;
        }
        if (options.additional_checks === "scope_path") {
          scope_matched = actual_customer_id === params.customer_id;
        }
        if (options.additional_checks === "central_id") {
          scope_matched =
            actual_customer_id === "scope.cust-" + params.customer_id ||
            actual_customer_id === "cnx.cust-" + params.customer_id;
        }
        return data;
      },
      function (reason) {
        status = "error";
        scope_found = false;
        scope_matched = false;
        return Promise.reject(reason);
      }
    );
  }
</script>

<!-- CONTENT -->
<div class="container">
  <div class="row">
    <div class="col-sm-offset-2 col-sm-10">
      <h1>Validate NAS ID</h1>
    </div>
  </div>

  <form class="form-horizontal" name="form">
    <div class="form-group">
      <!-- svelte-ignore a11y-label-has-associated-control -->
      <label class="col-sm-2 control-label">NAS ID</label>
      <div class="col-sm-8">
        <div class="row">
          <div class="col-xs-8">
            <input bind:value={options.nas_id} class="form-control" name="nas_id" required />
          </div>
        </div>
      </div>
    </div>

    <div class="form-group">
      <!-- svelte-ignore a11y-label-has-associated-control -->
      <label class="col-sm-2 control-label">Additional Checks</label>
      <div class="col-sm-8">
        <div class="radio">
          <label for="additional_none">
            <input
              type="radio"
              name="additional_checks"
              id="additional_none"
              bind:group={options.additional_checks}
              value="none"
              on:click={() => (status = "init")}
            />
            None
          </label>
        </div>
        <div class="radio">
          <label for="additional_central_id">
            <input
              type="radio"
              name="additional_checks"
              id="additional_central_id"
              bind:group={options.additional_checks}
              value="central_id"
              on:click={() => (status = "init")}
            />
            Central ID — Check the NAS ID belongs to a specific Central customer
          </label>
        </div>
        <div class="radio">
          <label for="additional_scope_path">
            <input
              type="radio"
              name="additional_checks"
              id="additional_scope_path"
              bind:group={options.additional_checks}
              value="scope_path"
              on:click={() => (status = "init")}
            />
            Scope Path — Check the NAS ID belongs to a scope
          </label>
        </div>
      </div>
    </div>

    {#if options.additional_checks === "central_id"}
      <div class="form-group">
        <!-- svelte-ignore a11y-label-has-associated-control -->
        <label class="col-sm-2 control-label">Central ID</label>
        <div class="col-sm-8">
          <div class="row">
            <div class="col-xs-8">
              <input bind:value={options.customer_id} class="form-control" name="customer_id" />
            </div>
          </div>
        </div>
      </div>
    {/if}

    {#if options.additional_checks === "scope_path"}
      <div class="form-group">
        <!-- svelte-ignore a11y-label-has-associated-control -->
        <label class="col-sm-2 control-label">Scope Path</label>
        <div class="col-sm-8">
          <div class="row">
            <div class="col-xs-8">
              <input
                bind:value={options.customer_id}
                class="form-control"
                name="customer_id"
                placeholder="scope.some.path"
              />
            </div>
          </div>
        </div>
      </div>
    {/if}

    <div class="form-group">
      <div class="col-sm-offset-2 col-sm-8">
        <button
          disabled={status === "running" || !options.nas_id}
          on:click|preventDefault={check_nas_id}
          class="btn btn-lg btn-primary"
        >
          Validate
        </button>
      </div>
    </div>
    {#if status === "done"}
      <div class="row stack-2">
        <div class="col-sm-offset-2 col-sm-8">
          {#if !scope_found}
            <p class="text-danger">
              <span class="glyphicon glyphicon-remove-circle" />&nbsp;NAS ID is not valid on this server.
            </p>
          {/if}
          {#if scope_found}
            <p class="text-success">
              <span class="glyphicon glyphicon-ok-circle" />&nbsp;NAS ID valid and belongs to:
              <strong>{actual_customer_id}</strong>
            </p>
          {/if}
          {#if scope_matched && options.additional_checks === "central_id"}
            <p class="text-success">
              <span class="glyphicon glyphicon-ok-circle" />&nbsp;Central Customer Id matches.
            </p>
          {/if}
          {#if !scope_matched && options.additional_checks === "central_id"}
            <p class="text-danger">
              <span class="glyphicon glyphicon-remove-circle" />&nbsp;Central Customer Id does not match.
            </p>
          {/if}
          {#if scope_matched && options.additional_checks === "scope_path"}
            <p class="text-success">
              <span class="glyphicon glyphicon-ok-circle" />&nbsp;Scope Path matches
            </p>
          {/if}
          {#if !scope_matched && options.additional_checks === "scope_path"}
            <p class="text-danger">
              <span class="glyphicon glyphicon-remove-circle" />&nbsp;Scope Path does not match
            </p>
          {/if}
        </div>
      </div>
    {/if}
    {#if status === "error"}
      <div class="row stack-2">
        <div class="col-sm-offset-2 col-sm-8">
          <p class="text-danger">
            <span class="glyphicon glyphicon-remove-circle" />&nbsp;Call to server failed, check logs or Diagnostics
            &gt; Errors
          </p>
        </div>
      </div>
    {/if}
  </form>
</div>
