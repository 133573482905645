import {getContext, setContext} from "svelte";
import {writable, type Readable, type Writable} from "svelte/store";
import type {Status} from "../api/services/system_health";

const KEY = Symbol();

export type SystemHealthStatus = Status | "unknown" | "normal";

export function init_system_health_status_context() {
  const system_health_status = writable<SystemHealthStatus>("unknown");
  setContext(KEY, system_health_status);
}

export function set_system_health_status(status: SystemHealthStatus) {
  require_system_health_status_context().set(status);
}

export function require_system_health_status(): Readable<SystemHealthStatus> {
  return require_system_health_status_context();
}

function require_system_health_status_context(): Writable<SystemHealthStatus> {
  const system_health_status = getContext<Writable<SystemHealthStatus>>(KEY);
  if (system_health_status) {
    return system_health_status;
  } else {
    throw "required system health status context is not available; use 'init_system_health_status_context' to initialise";
  }
}
