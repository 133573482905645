import {cg_support_api, type DefaultApiTypes} from "../api";

export type ServerIpAddress = string;

export interface ServersList {
  items: ServerIpAddress[];
}

interface ApiTypes extends DefaultApiTypes {
  get_result: ServersList;
}

export function service_role_api(role: string) {
  return cg_support_api<ApiTypes>("/api/v1/services/roles/:role", {role});
}
