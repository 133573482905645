import {cg_support_api, type DefaultApiTypes} from "../api";

export interface TestEmail {
  address: string;
  use_queue?: boolean;
}

interface ApiTypes extends DefaultApiTypes {
  post_data: TestEmail;
}

export function send_email() {
  return cg_support_api<ApiTypes>("/api/v1/messaging/send_email");
}
