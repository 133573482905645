import {cg_support_api, type DefaultApiTypes} from "../api";
import type {TaskExecution} from "./task_execution";

export interface TaskStats {
  running: number;
  success: number;
  failure: number;
}

export interface TaskStatusResult {
  running: number;
  success: number;
  failure: number;
  unknown: number;
  tasks: {
    [task_name: string]: TaskStats;
  };
  executing: TaskExecution[];
}

interface ApiTypes extends DefaultApiTypes {
  get_result: TaskStatusResult;
}

export function task_status_api(role: string, instance: string, queue_name: string) {
  return cg_support_api<ApiTypes>("/api/v1/queues/task_servers/:role/:instance/status/:queue_name", {
    role,
    instance,
    queue_name,
  });
}
