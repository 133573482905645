import type {Params} from "../api";
import {Endpoint} from "./inspect_endpoint";

export class Inspect {
  #refs: {[path: string]: Endpoint};

  constructor() {
    this.#refs = {};
  }

  endpoint(path: string, params: Params) {
    if (!this.#refs[path]) {
      this.#refs[path] = new Endpoint(path, params);
      this.#refs[path].refresh().catch((reason) => {
        console.log(`failed to load endpoint: ${path}`, reason);
      });
    }

    return this.#refs[path];
  }
}

export function inspect() {
  return new Inspect();
}
