<script lang="ts">
  import {send_email} from "$app/support/api/messaging/send_email";
  import Alert from "$lib/Alert.svelte";
  import Spinner from "$lib/spinkit/Spinner.svelte";

  let email = {
    address: "",
    status: "ready" as "ready" | "sending" | "success" | "failure",
    queue: "queue" as "queue" | "bypass",
  };

  function send() {
    email.status = "sending";
    send_email()
      .post(undefined, {
        address: email.address,
        use_queue: email.queue === "queue",
      })
      .then(
        function (data) {
          email.status = "success";
          return data;
        },
        function (reason) {
          email.status = "failure";
          return Promise.reject(reason);
        }
      );
  }

  function acknowledge() {
    email.status = "ready";
  }

  $: can_send = !!email.address;
</script>

<!-- CONTENT -->
<div class="container">
  <div class="row">
    <div class="col-sm-offset-2 col-sm-10">
      <h1>Send Email</h1>
    </div>
  </div>

  <form class="form-horizontal" name="send_email_form">
    <div class="form-group">
      <!-- svelte-ignore a11y-label-has-associated-control -->
      <label class="col-sm-2 control-label">Send to</label>
      <div class="col-sm-8">
        <input bind:value={email.address} type="email" class="form-control" required />
        {#if !email.address}
          <p class="help-block">Enter an email address to send a test email message to.</p>
        {:else}
          <p class="help-block">
            A test email message will be sent to the address:
            <strong>{email.address}</strong>
          </p>
        {/if}
      </div>
    </div>
    <div class="form-group">
      <!-- svelte-ignore a11y-label-has-associated-control -->
      <label class="col-sm-2 control-label">Queue</label>
      <div class="col-sm-8">
        <select bind:value={email.queue} class="form-control" required>
          <option value="queue">Queued — Use standard queue</option>
          <option value="bypass">Bypass — Direct to provider</option>
        </select>
        {#if email.queue == "queue"}
          <div>
            <p class="help-block">
              The email message will be
              <strong>queued</strong> for delivery on the same standard message queue as used by the main application.
            </p>
            <p class="help-block">
              Use this option to verify that email message queue processing as well as email delivery is working as
              expected.
            </p>
          </div>
        {/if}
        {#if email.queue == "bypass"}
          <div>
            <p class="help-block">
              The email message will be sent
              <strong>directly</strong> to the email provider. The standard message queue as used by the main
              application will <strong>not</strong> be used.
            </p>
            <p class="help-block">
              Use this option to
              <strong>bypass</strong> the standard message queue and only verify that the email provider is working as expected.
            </p>
          </div>
        {/if}
      </div>
    </div>
    <div class="form-group">
      <div class="col-sm-offset-2 col-sm-8">
        {#if email.status == "ready"}
          <button on:click|preventDefault={send} disabled={!can_send} class="btn btn-lg btn-primary">Send</button>
        {/if}
        {#if email.status == "sending"}
          <button disabled class="btn btn-lg btn-primary">
            Sending
            <Spinner type="circle-fade" />
          </button>
        {/if}
        {#if email.status == "success"}
          <Alert type="success" close={acknowledge}>Message sent</Alert>
        {/if}
        {#if email.status == "failure"}
          <Alert type="danger" close={acknowledge}>Failed to send the message</Alert>
        {/if}
      </div>
    </div>
  </form>
</div>
