import {RabbitMQ} from "./rabbitmq";
import {Tasks} from "./tasks";

export class Queues {
  #rabbitmq: RabbitMQ;
  #tasks: Tasks;

  constructor() {
    this.#rabbitmq = new RabbitMQ();
    this.#tasks = new Tasks();
  }

  get rabbitmq() {
    return this.#rabbitmq;
  }

  get tasks() {
    return this.#tasks;
  }

  refresh() {
    this.#rabbitmq.refresh();
    this.#tasks.refresh();
  }
}
