<script context="module" lang="ts">
  import {inspect} from "$app/support/api/inspect";
  import type {Params} from "./types";

  interface KVP {
    key?: string;
    parent_key?: string;
    value: any;
    $expand?: boolean;
  }

  function group_kvps(dataset: KVP[]): KVP[][] {
    let rgroup: KVP[] | undefined = undefined;
    let groups: {[key: string]: KVP[]} = {};
    let grouped: KVP[][] = [];

    for (const kvp of dataset) {
      let group: KVP[];
      if (kvp.parent_key !== undefined) {
        if (groups[kvp.parent_key] === undefined) {
          group = groups[kvp.parent_key] = [];
          grouped.push(group);
        } else {
          group = groups[kvp.parent_key];
        }
      } else {
        if (rgroup === undefined) {
          group = rgroup = [];
          grouped.unshift(group);
        } else {
          group = rgroup;
        }
      }
      group.push(kvp);
    }

    return grouped;
  }

  function as_kvps(dataset: KVP[], data: any, key?: string, parent_key?: string): KVP[] {
    function extended_key(key?: string) {
      if (parent_key !== undefined) {
        if (key !== undefined) {
          return String(parent_key) + "." + String(key);
        } else {
          return String(parent_key);
        }
      } else {
        return key;
      }
    }

    if (Array.isArray(data)) {
      if (data.length === 0) {
        dataset.push({key, parent_key, value: data});
      } else {
        data.forEach(function (item, index) {
          if (typeof item !== "function") {
            dataset = as_kvps(dataset, item, `${index}`, extended_key(key));
          }
        });
      }
    } else if (typeof data === "object" && data !== null) {
      const keys: string[] = [];

      for (const [subkey, item] of Object.entries(data)) {
        if (subkey[0] !== "$" && typeof item !== "function") {
          keys.push(subkey);
        }
      }

      if (keys.length === 0) {
        dataset.push({key, parent_key, value: data});
      } else {
        keys.sort();
        for (const subkey of keys) {
          dataset = as_kvps(dataset, data[subkey], subkey, extended_key(key));
        }
      }
    } else {
      if (typeof data !== "function") {
        dataset.push({key, parent_key, value: data});
      }
    }

    return dataset;
  }
</script>

<script lang="ts">
  import Json from "$lib/Json.svelte";
  import Spinner from "$lib/spinkit/Spinner.svelte";
  import Data from "$lib/Data.svelte";

  export let path: string;
  export let params: Params;
  export let key: string | undefined = undefined;
  export let parent_key: string | undefined = undefined;

  const REF_RE = new RegExp("^/[-_./a-zA-Z0-9]+$");

  const options = {
    show_list: true,
    show_json: false,
  };
  let groups: KVP[][] = [];

  const sub_params: Pick<Params, "private_info"> = {};

  function is_ref(kvp: KVP) {
    return typeof kvp.value === "string" && kvp.value[0] === "/" && REF_RE.test(kvp.value);
  }

  function toggle_list_view() {
    options.show_list = !options.show_list;
  }

  function toggle_json_view() {
    options.show_json = !options.show_json;
  }

  function expandable(kvp: KVP) {
    return kvp.key !== "path";
  }

  function href_for(kvp: KVP) {
    var base = "#/inspect";
    if (path === "/api-docs") {
      base += "/api-docs";
    }
    return base + (typeof kvp.value === "string" ? kvp.value : "");
  }

  $: endpoint = inspect().endpoint(path, params);
  $: endpoint_status = endpoint.status;
  $: endpoint_data = endpoint.data;
  $: endpoint_error = endpoint.error;
  $: if (params.private_info) {
    sub_params.private_info = true;
  } else {
    delete sub_params.private_info;
  }
  $: if ($endpoint_status === "loaded") {
    groups = group_kvps(as_kvps([], $endpoint_data, key, parent_key));
  } else if ($endpoint_status === "error") {
    groups = group_kvps(as_kvps([], $endpoint_error, key, parent_key));
  }
</script>

<div>
  {#if $endpoint_status == "init" || $endpoint_status == "loading"}
    <div class="row">
      <div class="col-sm-offset-4 col-sm-8 stack-1">
        <span class="label label-default"> Loading <Spinner type="circle-fade" /> </span>
      </div>
    </div>
  {/if}
  {#if $endpoint_status === "error"}
    <div class="row">
      <div class="col-sm-4 text-right-sm stack-1">
        <span class="label label-warning">ERROR</span>
      </div>
      <div class="col-sm-8 stack-1">
        <strong>Failed to load the resource at <code>{path}</code></strong>
      </div>
    </div>
  {/if}
  {#each groups as group, index}
    {#if options.show_list || index === 0}
      <div class="row">
        <div class="col-sm-offset-4 col-sm-8 stack-1">
          <code class="label label-primary">
            <span>{group[0].parent_key ?? ""}</span>
            <span class="glyphicon glyphicon-triangle-bottom" />
          </code>
          &nbsp;
          {#if index === 0}
            <span class="btn-group btn-group-xs">
              <button
                on:click|preventDefault={toggle_list_view}
                class:active={options.show_list}
                class="btn btn-default"
              >
                <span class="glyphicon glyphicon-list" />
              </button>
              <button
                on:click|preventDefault={toggle_json_view}
                class:active={options.show_json}
                class="btn btn-default"
              >
                <samp><strong>{"{"}&hellip;{"}"}</strong></samp>
              </button>
            </span>
          {/if}
        </div>
      </div>
    {/if}
    {#if !options.show_list && !options.show_json && index === 0}
      <div>
        <div class="row">
          <div class="col-sm-offset-4 col-sm-8 stack-1 text-muted">
            <span class="glyphicon glyphicon-info-sign" />
            View this data as a
            <button
              on:click|preventDefault={toggle_list_view}
              class:active={options.show_list}
              class="btn btn-default btn-xs"
            >
              <span class="glyphicon glyphicon-list" /> list
            </button>
            and/or as raw
            <button
              on:click|preventDefault={toggle_json_view}
              class:active={options.show_json}
              class="btn btn-default btn-xs"
            >
              <samp><strong>{"{"}&hellip;{"}"}</strong></samp> JSON
            </button>
          </div>
        </div>
      </div>
    {/if}
    {#if options.show_json && index === 0}
      <div>
        <div class="row">
          <div class="col-sm-offset-2 col-sm-10 stack-1">
            {#if $endpoint_status == "loaded"}
              <pre><Json value={$endpoint_data} /></pre>
            {:else if $endpoint_status == "error"}
              <pre><Json value={$endpoint_error} /></pre>
            {/if}
          </div>
        </div>
      </div>
    {/if}
    {#if options.show_list}
      {#each group as kvp}
        <div>
          <div class="row">
            <div class="col-sm-4 text-right-sm stack-1 text-muted">
              {kvp.key ?? ""}
            </div>
            <div class="col-sm-8 stack-1">
              {#if kvp.key === "name"}
                <strong><Data value={kvp.value} /></strong>
              {:else}
                <Data value={kvp.value} />
              {/if}
              {#if is_ref(kvp)}
                <span class="btn-group btn-group-xs">
                  {#if expandable(kvp)}
                    <button on:click|preventDefault={() => (kvp.$expand = !kvp.$expand)} class="btn btn-default">
                      {#if !kvp.$expand}
                        <span> <span class="glyphicon glyphicon-expand" /> expand </span>
                      {:else}
                        <span> <span class="glyphicon glyphicon-collapse-up" /> collapse </span>
                      {/if}
                    </button>
                  {/if}
                  <a href={href_for(kvp)} class="btn btn-default">
                    <span class="glyphicon glyphicon-chevron-right" /> inspect
                  </a>
                </span>
              {/if}
            </div>
          </div>
          {#if kvp.$expand}
            <svelte:self path={kvp.value} key={kvp.key} parent_key={kvp.parent_key} params={sub_params} />
          {/if}
        </div>
      {/each}
    {/if}

    {#if options.show_list && index !== 0}
      <div class="row">
        <div class="col-sm-offset-4 col-sm-8 stack-1">
          <code class="label label-primary">
            <span>{group[0].parent_key ?? ""}</span>
            <span class="glyphicon glyphicon-triangle-top" />
          </code>
        </div>
      </div>
    {/if}

    {#if index + 1 === groups.length}
      <div class="row">
        <div class="col-sm-offset-4 col-sm-8 stack-1">
          <code class="label label-primary">
            <span>{groups[0][0].parent_key ?? ""}</span>
            <span class="glyphicon glyphicon-triangle-top" />
          </code>
          &nbsp;
          <span class="btn-group btn-group-xs">
            <button on:click|preventDefault={toggle_list_view} class:active={options.show_list} class="btn btn-default">
              <span class="glyphicon glyphicon-list" />
            </button>
            <button on:click|preventDefault={toggle_json_view} class:active={options.show_json} class="btn btn-default">
              <samp><strong>{"{"}&hellip;{"}"}</strong></samp>
            </button>
          </span>
        </div>
      </div>
    {/if}
  {/each}
</div>
