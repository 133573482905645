<script context="module" lang="ts">
  import {derived, writable} from "svelte/store";
  import Data from "$lib/Data.svelte";
  import {format_iso8601} from "$lib/date";
  import Spinner from "$lib/spinkit/Spinner.svelte";
  import {Errors} from "$support/api/errors";
  import type {Error} from "$support/api/errors";

  interface ModifiedError extends Error {
    $expand?: boolean;
  }

  interface Field {
    key: keyof Error;
    hide_key?: boolean;
  }

  interface Group {
    name: string;
    fields: Field[];
    ignore?: (error: ModifiedError) => boolean;
  }

  const groups: Group[] = [
    {
      name: "Error",
      fields: [{key: "timestamp"}, {key: "type"}, {key: "value"}],
    },

    {
      name: "Deployment",
      fields: [{key: "version"}, {key: "revision"}, {key: "server"}, {key: "hostname"}],
    },

    {
      name: "Request",
      fields: [{key: "method"}, {key: "url"}, {key: "headers"}],
      ignore: function (error: ModifiedError) {
        return !error.method && !error.url && !error.headers;
      },
    },

    {
      name: "Task",
      fields: [{key: "args"}, {key: "kwargs"}],
      ignore: function (error: ModifiedError) {
        return error.args === "None" && error.kwargs === "None";
      },
    },

    {
      name: "Backtrace",
      fields: [{key: "backtrace", hide_key: true}],
    },
  ];
</script>

<script lang="ts">
  const errors = new Errors();
  const loading = errors.$loading;
  const update = writable(0);
  const loaded_errors = derived<[typeof errors.errors, typeof update], ModifiedError[]>(
    [errors.errors, update],
    ([errors, update], set) => set(errors)
  );

  function toggle_expand(error: ModifiedError) {
    error.$expand = !error.$expand;
    $update = $update + 1; // Force an update of `loaded_errors`.
  }

  errors.refresh();
</script>

<div class="container">
  <div class="row">
    <div class="col-xs-12">
      <h1>Errors</h1>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-12">
      <h2>
        Unhandled Exceptions
        {#if $loading}
          <small><Spinner type="circle-fade" /> </small>
        {/if}
      </h2>
    </div>
  </div>

  <div class="row stack-1">
    <div class="col-xs-12 text-right">
      <form class="form-inline form-inline-xs">
        <div class="form-group">
          <button on:click|preventDefault={() => errors.refresh()} class="btn btn-default">
            <span class="glyphicon glyphicon-refresh" />
            <span class="hidden-xs">Refresh</span>
          </button>
        </div>
      </form>
    </div>
  </div>

  {#if !$loading && $loaded_errors.length === 0}
    <div class="alert alert-success">
      <strong>Well done team!</strong> There are no unhandled exceptions.
    </div>
  {/if}

  {#each $loaded_errors as error}
    <div class="row">
      <div class="col-xs-12 col-sm-5 col-md-4 stack-1">
        {format_iso8601(error.timestamp, "long")} ({format_iso8601(error.timestamp, "ago")})
      </div>
      <div class="col-xs-12 col-sm-6 col-md-7 stack-1">
        {error.type}: {error.value}
        {#if error.method && error.url}
          <div>(processing {error.method} {error.url})</div>
        {/if}
      </div>
      <div class="col-xs-12 col-sm-1 col-md-1 stack-1">
        <button on:click={() => toggle_expand(error)} class="btn btn-xs btn-default">
          {#if !error.$expand}
            <span> <span class="glyphicon glyphicon-expand" /> expand </span>
          {:else}
            <span> <span class="glyphicon glyphicon-collapse-up" /> collapse </span>
          {/if}
        </button>
      </div>
    </div>
    {#if error.$expand}
      {#each groups as group}
        {#if !group.ignore || !group.ignore(error)}
          <div>
            <div class="row">
              <div class="col-xs-12 col-sm-5 col-md-4 stack-1" />
              <div class="col-xs-12 col-sm-7 col-md-8 stack-1">
                <code class="label label-primary">{group.name}</code>
              </div>
            </div>
            {#each group.fields as field}
              <div class="row">
                {#if !field.hide_key}
                  <div class="col-xs-12 col-sm-5 col-md-4 stack-1 text-right-sm text-muted">
                    {field.key}
                  </div>
                {/if}
                <div class="col-xs-12 stack-1" class:col-sm-7={!field.hide_key} class:col-md-8={!field.hide_key}>
                  <Data value={error[field.key]} />
                </div>
              </div>
            {/each}
          </div>
        {/if}
      {/each}
    {/if}
  {/each}
</div>
