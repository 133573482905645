<script context="module" lang="ts">
  function update_classes(classes?: string) {
    const cls = ["progress"];
    if (classes) {
      cls.push(classes);
    }
    return cls.join(" ");
  }
</script>

<script lang="ts">
  let classes: string | undefined = undefined;
  export {classes as class};
  export let id: string | undefined = undefined;

  $: cls = update_classes(classes);
</script>

<div class={cls} {id}>
  <!-- The slot should contain <ProgressBar> components. -->
  <slot />
</div>
