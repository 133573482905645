import {cg_support_api, type DefaultApiTypes, type Data} from "../api";

export interface SmsMessage {
  sid: string;
  date_created: string;
  date_updated: string;
  date_sent: string;
  account_sid: string;
  to: string;
  messaging_service_sid: string;
  body: string;
  status: string;
  num_segments: string;
  num_media: string;
  direction: string;
  api_version: string;
  price: string;
  price_unit: string;
  error_code: string;
  error_message: string;
  uri: string;
}

export interface SmsHistoryResult {
  items: SmsMessage[];
}

interface PostData extends Data {
  phone_number: string;
  scope?: string | null;
}

interface ApiTypes extends DefaultApiTypes {
  post_result: SmsHistoryResult;
  post_data: PostData;
}

export function sms_history() {
  return cg_support_api<ApiTypes>("/api/v1/messaging/sms_history");
}
