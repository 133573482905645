<script context="module" lang="ts">
  import Nav from "$lib/nav/Nav.svelte";
  import Title from "./Title.svelte";
  import FavIcon from "./FavIcon.svelte";
  import Header from "./Header.svelte";
  import LoginCheck from "./LoginCheck.svelte";
  import {init_system_health_status_context} from "./system_health/context";
  import {init_system_info_context, require_system_info} from "./system_info/context";
  import type {ApiDocsSystemInfoResult} from "./api/services/system_info";

  // Views
  import SystemHealth from "./route/system_health/SystemHealth.svelte";
  import Database from "./route/diagnostics/database/Database.svelte";
  import EmailHistory from "./route/diagnostics/email/history/EmailHistory.svelte";
  import SendEmail from "./route/diagnostics/email/send/SendEmail.svelte";
  import ApiCallLogs from "./route/diagnostics/logs/api_call/ApiCallLogs.svelte";
  import MacAddressLogs from "./route/diagnostics/logs/mac_address/MacAddressLogs.svelte";
  import Inspect from "./route/inspect/Inspect.svelte";
  import Redis from "./route/diagnostics/redis/Redis.svelte";
  import SendSms from "./route/diagnostics/sms/send/SendSms.svelte";
  import SmsHistory from "./route/diagnostics/sms/history/SmsHistory.svelte";
  import Errors from "./route/diagnostics/errors/Errors.svelte";
  import NasIdCheck from "./route/diagnostics/nas/NasIdCheck.svelte";
  import Queues from "./route/diagnostics/queues/Queues.svelte";
  import RadSecStats from "./route/diagnostics/radsec/stats/RadSecStats.svelte";
  import RadSecConnections from "./route/diagnostics/radsec/connections/RadSecConnections.svelte";
  import RadSecProxyConnections from "./route/diagnostics/radsec/proxy/RadSecProxyConnections.svelte";
  import RadSecCaptures from "./route/diagnostics/radsec/captures/RadSecCaptures.svelte";
  import TestScope from "./route/diagnostics/test_scope/TestScope.svelte";

  function api_docs_label(api_docs: ApiDocsSystemInfoResult): string {
    if (api_docs.version === 1) {
      return "v1 (Classic Central)";
    } else if (api_docs.version === 2) {
      return "v2 (New Central)";
    } else {
      return `v${api_docs.version}`;
    }
  }
</script>

<script lang="ts">
  init_system_health_status_context();
  init_system_info_context();
  const system_info = require_system_info();
</script>

<Nav router="hash" group>
  <Title />
  <FavIcon />
  <Header />
  <LoginCheck />
  <Nav level="primary" path="/system-health" label="System" initial>
    <SystemHealth />
  </Nav>
  <Nav level="primary" label="Diagnostics" group>
    <Nav level="primary" path="/database" label="Database">
      <Database />
    </Nav>
    <Nav level="primary" path="/send-email" label="Email" group>
      <Nav level="secondary" label="Email:" />
      <Nav level="secondary" path="/send-email" label="Send">
        <SendEmail />
      </Nav>
      <Nav level="secondary" path="/email-history" label="History">
        <EmailHistory />
      </Nav>
    </Nav>
    <Nav level="primary" path="/errors" label="Errors">
      <Errors />
    </Nav>
    <Nav level="primary" path="/api-call-logs" label="Logs" group>
      <Nav level="secondary" label="Logs:" />
      <Nav level="secondary" path="/api-call-logs" label="API Calls">
        <ApiCallLogs />
      </Nav>
      <Nav level="secondary" path="/mac-address-logs" label="Client MAC Addresses">
        <MacAddressLogs />
      </Nav>
    </Nav>
    <Nav level="primary" path="/nas_id_check" label="NAS" group>
      <Nav level="secondary" label="NAS:" />
      <Nav level="secondary" path="/nas_id_check" label="Validate ID">
        <NasIdCheck />
      </Nav>
    </Nav>
    <Nav level="primary" path="/queues" label="Queues">
      <Queues />
    </Nav>
    <Nav level="primary" path="/radsec/stats" label="RadSec" group>
      <Nav level="secondary" label="RadSec:" />
      <Nav level="secondary" path="/radsec/stats" label="Statistics">
        <RadSecStats source="stats" />
      </Nav>
      <Nav level="secondary" path="/radsec/connections" label="Connections">
        <RadSecConnections />
      </Nav>
      <Nav level="secondary" path="/radsec/proxy_stats" label="Proxy Statistics">
        <RadSecStats source="proxy_stats" />
      </Nav>
      <Nav level="secondary" path="/radsec/proxy_connections" label="Proxy Connections">
        <RadSecProxyConnections />
      </Nav>
      <Nav level="secondary" path="/radsec/captures" label="Captures">
        <RadSecCaptures />
      </Nav>
    </Nav>
    <Nav level="primary" path="/redis" label="Redis">
      <Redis />
    </Nav>
    <Nav level="primary" path="/send-sms" label="SMS" group>
      <Nav level="secondary" label="SMS:" />
      <Nav level="secondary" path="/send-sms" label="Send">
        <SendSms />
      </Nav>
      <Nav level="secondary" path="/sms-history" label="History">
        <SmsHistory />
      </Nav>
    </Nav>
    <Nav level="primary" path="/test-scope" label="Test Scope">
      <TestScope />
    </Nav>
  </Nav>
  <Nav
    level="primary"
    label="Inspect"
    path="/inspect"
    nest
    params={{search: "string", offset: "number", limit: "number", calculate_count: "boolean", private_info: "boolean"}}
    let:nested
    let:params
  >
    <Inspect base="/inspect" path={nested} {params} />
  </Nav>
  {#if $system_info.logs_url}
    <Nav level="primary" path={$system_info.logs_url} external label="Logs" />
  {/if}
  {#if $system_info.api_docs && $system_info.api_docs.length}
    <Nav level="primary" label="OpenAPI Live Docs" group>
      {#each $system_info.api_docs as api_docs}
        <Nav level="primary" path={api_docs.url} external label={api_docs_label(api_docs)} />
      {/each}
    </Nav>
  {/if}
</Nav>
