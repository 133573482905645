import {writable, type Writable, type Readable, derived} from "svelte/store";
import {cg_support_api, type Params} from "../api";

export function inspect_endpoint(path: string) {
  if (typeof path === "string" && path && path[0] === "/") {
    return cg_support_api(`/api/v1/inspect/endpoint${path}`);
  }
}

export type Status = "init" | "loading" | "loaded" | "error";

interface State {
  status: Status;
  data?: any;
  error?: any;
}

export class Endpoint {
  #path: string;
  #params: Params;
  #state: Writable<State>;
  #status: Readable<Status>;
  #data: Readable<any>;
  #error: Readable<any>;

  constructor(path: string, params: Params) {
    this.#path = path;
    this.#params = {...params};
    this.#state = writable({status: "init"});
    this.#status = derived(this.#state, (state, set) => set(state.status));
    this.#data = derived(this.#state, (state, set) => set(state.data));
    this.#error = derived(this.#state, (state, set) => set(state.error));
  }

  get status() {
    return this.#status;
  }

  get data() {
    return this.#data;
  }

  get error() {
    return this.#error;
  }

  refresh() {
    const endpoint = inspect_endpoint(this.#path);
    if (endpoint) {
      this.#state.update((state) => {
        state.status = "loading";
        return state;
      });
      return endpoint.get(this.#params).then(
        (data) => {
          this.#state.set({status: "loaded", data});
        },
        async (reason) => {
          if (reason instanceof Response) {
            reason = {
              status: reason.status,
              statusText: reason.statusText,
              headers: Object.fromEntries(reason.headers.entries()),
              body: await reason.text(),
            };
          }
          this.#state.set({status: "error", error: reason});
        }
      );
    } else {
      this.#state.set({status: "error", error: {reason: `invalid endpoint: ${this.#path}`}});
      return Promise.reject(`invalid endpoint: ${this.#path}`);
    }
  }
}
