import {writable, type Readable, type Writable, derived} from "svelte/store";
import {Loadable} from "../loader";
import {cg_support_api, type DefaultApiTypes, type Params as BaseParams} from "../api";
import type {RadSecConnectionFields} from "./connections";
import type {BaseRadSecStats, RadSecCodeCount} from "./stats";

export interface ConnectionInfo extends RadSecConnectionFields {
  cert_subject: string;
  cert_issuer: string;
  cert_pem: string;
}

export interface ConnectionStats extends BaseRadSecStats {
  connect_time: string;
}

export interface ConnectionStatsAlt
  extends Pick<
    ConnectionStats,
    "bytes_sent" | "bytes_received" | "packets_sent" | "packets_received" | "connect_time"
  > {
  code_sent: RadSecCodeCount[];
  code_received: RadSecCodeCount[];
}

export interface Params {
  connection_id: string;
}

interface GetParams extends BaseParams, Params {}

interface ConnectionDetails {
  info: ConnectionInfo;
  stats: ConnectionStats;
}

interface ConnectionDetailsAlt {
  info: ConnectionInfo;
  stats: ConnectionStatsAlt;
}

interface ApiTypes extends DefaultApiTypes {
  get_result: ConnectionDetails;
  get_params: GetParams;
}

export class Connection extends Loadable<ConnectionDetails> {
  #params: Params;
  #details: Writable<ConnectionDetailsAlt>;
  #info: Readable<ConnectionInfo>;
  #stats: Readable<ConnectionStatsAlt>;

  constructor(params: Params) {
    super();

    this.#params = {...params};
    this.#details = writable(default_details());
    this.#info = derived(this.#details, (details, set) => {
      set(details.info);
    });
    this.#stats = derived(this.#details, (details, set) => {
      set(details.stats);
    });
  }

  get info(): Readable<ConnectionInfo> {
    return this.#info;
  }

  get stats(): Readable<ConnectionStatsAlt> {
    return this.#stats;
  }

  async $load(): Promise<ConnectionDetails> {
    return cg_support_api<ApiTypes>("/api/v1/radsec/connections/:connection_id").get({...this.#params});
  }

  $validate(data: ConnectionDetails): boolean {
    return (
      typeof data === "object" &&
      data !== null &&
      typeof data.info === "object" &&
      data.info !== null &&
      typeof data.stats === "object" &&
      data.stats !== null
    );
  }

  $set(data: ConnectionDetails): void {
    this.#details.set({
      info: data.info,
      stats: {
        ...data.stats,
        code_sent: Object.entries(data.stats.code_sent).map(([code, count]) => ({code, count})),
        code_received: Object.entries(data.stats.code_received).map(([code, count]) => ({code, count})),
      },
    });
  }

  $clear(): void {
    this.#details.set(default_details());
  }
}

function default_details(): ConnectionDetailsAlt {
  return {
    info: {
      local_addr: "",
      remote_addr: "",
      nas_id: "",
      mac_address: "",
      cert_serial: "",
      cert_subject: "",
      cert_issuer: "",
      cert_pem: "",
    },
    stats: {
      bytes_sent: 0,
      bytes_received: 0,
      packets_sent: 0,
      packets_received: 0,
      code_sent: [],
      code_received: [],
      connect_time: "",
    },
  };
}
