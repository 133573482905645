import {cg_support_api, type DefaultApiTypes, type Params} from "../api";

export interface EmailMessage {
  from_email: string;
  msg_id: string;
  subject: string;
  to_email: string;
  status: string;
  opens_count: string;
  clicks_count: string;
  last_event_time: string;
}

export interface EmailHistoryResult {
  items: EmailMessage[];
}

interface GetParams extends Params {
  to_email: string;
  limit?: number;
}

interface ApiTypes extends DefaultApiTypes {
  get_result: EmailHistoryResult;
  get_params: GetParams;
}

export function email_history(to_email: string, limit?: number) {
  return cg_support_api<ApiTypes>("/api/v1/messaging/email_history").get({to_email, limit});
}
