import {cg_support_api, type DefaultApiTypes, type Params} from "../api";

export interface ApiCallLog {
  timestamp: string;
  method: string;
  path: string;
  status: number;
}

export interface ApiCallLogResult {
  items: ApiCallLog[];
  cursor: number;
}

interface GetParams extends Params {
  scopepath: string;
  cursor?: number;
}

interface ApiTypes extends DefaultApiTypes {
  get_result: ApiCallLogResult;
  get_params: GetParams;
}

export function api_call_logs(scopepath: string, cursor?: number) {
  return cg_support_api<ApiTypes>("/api/v1/elasticsearch/api_calls").get({scopepath, cursor});
}
