<script context="module" lang="ts">
  import {fade} from "svelte/transition";
  import {create_capture, delete_capture, get_captures} from "$app/support/api/radius_trace";
  import type {CreateRadiusTraceConfig, RadiusTraceConfig} from "$app/support/api/radius_trace";
  import Spinner from "$lib/spinkit/Spinner.svelte";
  import {onDestroy, onMount} from "svelte";

  function is_running(exp_date_str: string) {
    const diff = new Date(exp_date_str).getTime() - Date.now();
    return diff > 0;
  }

  function display_delta_time(earlier_time: string, later_time: string, colon_format: boolean) {
    return generateTimeUnitsStr(new Date(later_time).getTime() - new Date(earlier_time).getTime(), colon_format);
  }

  function generateTimeUnitsStr(msecs: number, colon_format: boolean) {
    let retval = "";
    if (msecs < 0) {
      retval = "-";
      msecs = Math.abs(msecs);
    }
    if (colon_format) {
      var hours = Math.floor(msecs / 1000 / 60 / 60);
      retval += hours + ":";
      msecs -= hours * 60 * 60 * 1000;
      var seconds = Math.floor(msecs / 1000 / 60);
      retval += seconds < 10 ? "0" + seconds : "" + seconds;
      return retval;
    } else {
      if (msecs < 1000) {
        return "< " + retval + "1 s";
      } else if (msecs < 1000 * 60) {
        return retval + (msecs / 1000).toFixed(0) + " s";
      } else if (msecs < 1000 * 60 * 60) {
        return retval + (msecs / 1000 / 60).toFixed(0) + " min";
      } else if (msecs < 1000 * 60 * 60 * 24) {
        return retval + (msecs / 1000 / 60 / 60).toFixed(0) + " h";
      } else {
        return retval + (msecs / 1000 / 60 / 60 / 24).toFixed(0) + " d";
      }
    }
  }

  type TimeZoneOption = "local time" | "UTC" | "relative time";
  type DurationOption = "5 minutes" | "15 minutes" | "1 hour" | "8 hours";
  type Options = {
    timezone: TimeZoneOption;
    timezones: TimeZoneOption[];
  };
  type Create = {
    api: CreateRadiusTraceConfig;
    is_collapsed: boolean;
    duration: DurationOption;
    durations: DurationOption[];
  };
  type SortField = keyof RadiusTraceConfig;

  function update(options: Options, create: Create, sort_field: SortField, sort_reverse: boolean) {
    function get_caret(field: SortField) {
      if (field === sort_field) {
        if (sort_reverse) {
          return "\u25bc";
        } else {
          return "\u25b2";
        }
      } else {
        return "\u00a0";
      }
    }

    function display_formatted_time(iso_date_str: string) {
      if (options.timezone === "UTC") {
        return new Date(iso_date_str).toISOString().substring(0, 19).replace("T", " ");
      } else if (options.timezone === "local time") {
        return new Date(new Date(iso_date_str).getTime() - new Date().getTimezoneOffset() * 60 * 1000)
          .toISOString()
          .substring(0, 19)
          .replace("T", " ");
      } else if (options.timezone === "relative time") {
        const delta = new Date().getTime() - new Date(iso_date_str).getTime();
        if (delta > 0) {
          return generateTimeUnitsStr(delta, false) + " ago";
        } else {
          return generateTimeUnitsStr(-delta, false) + " from now";
        }
      } else {
        console.log("ERROR: unknown time format");
        return;
      }
    }

    {
      return {
        get_caret,
        display_formatted_time,
      };
    }
  }

  function sort_captures(
    captures: RadiusTraceConfig[],
    key: keyof RadiusTraceConfig,
    ascending: boolean
  ): RadiusTraceConfig[] {
    // Copy before sorting.
    captures = [...captures];
    captures.sort((a, b) => {
      const av = a[key];
      const bv = b[key];
      return av < bv ? (ascending ? -1 : 1) : av === bv ? 0 : ascending ? 1 : -1;
    });
    return captures;
  }
</script>

<script lang="ts">
  const options = {
    timezone: "local time" as TimeZoneOption,
    timezones: ["local time", "UTC", "relative time"] as TimeZoneOption[],
  };

  const create: Create = {
    api: {
      certificate_subject: "",
      certificate_serial: "",
      nas_identifier: "",
      nas_mac: "",
      nas_addr: "",
      expires_at: "",
    },
    is_collapsed: true,
    duration: "5 minutes" as DurationOption,
    durations: ["5 minutes", "15 minutes", "1 hour", "8 hours"] as DurationOption[],
  };

  let status = "ready" as "ready" | "creating" | "success" | "failure";

  let unsorted_captures: RadiusTraceConfig[] = [];
  let captures: RadiusTraceConfig[] = [];
  let num_captures = 0;

  let sort_field = "created_at" as SortField;
  let sort_reverse = true;

  type DuplicableField = Exclude<Extract<keyof RadiusTraceConfig, keyof CreateRadiusTraceConfig>, "expires_at">;
  const duplicable_fields: DuplicableField[] = [
    "nas_addr",
    "certificate_subject",
    "certificate_serial",
    "nas_identifier",
    "nas_mac",
  ];

  function set_sort_field(order_by: SortField) {
    if (sort_field === order_by) {
      sort_reverse = !sort_reverse;
    } else {
      sort_field = order_by;
      if (order_by === "id") {
        sort_reverse = false;
      } else {
        sort_reverse = true;
      }
    }
  }

  function get_end_time_str() {
    let duration_msecs = 0;
    switch (create.duration) {
      case "5 minutes":
        duration_msecs = 5 * 60 * 1000;
        break;
      case "15 minutes":
        duration_msecs = 15 * 60 * 1000;
        break;
      case "1 hour":
        duration_msecs = 60 * 60 * 1000;
        break;
      case "8 hours":
        duration_msecs = 8 * 60 * 60 * 1000;
        break;
      default:
        duration_msecs = 8 * 60 * 60 * 1000;
        break;
    }
    return new Date(new Date().getTime() + duration_msecs).toISOString();
  }

  function click_get_captures() {
    do_get_captures();
  }

  function do_get_captures() {
    get_captures().then(
      (data) => {
        status = "success";
        num_captures = !data.count ? 0 : data.count;
        unsorted_captures = data.items;
        return data;
      },
      (reason) => {
        status = "failure";
        return Promise.reject(reason);
      }
    );
  }

  function click_show_capture_creation_form() {
    create.is_collapsed = false;
  }

  function click_create_capture() {
    do_create_capture();
  }

  function do_create_capture() {
    const body: CreateRadiusTraceConfig = {
      expires_at: get_end_time_str(),
    };
    Object.keys(create.api).forEach(function (key) {
      const value = create.api[key as keyof CreateRadiusTraceConfig];
      if (value) {
        body[key as keyof CreateRadiusTraceConfig] = value;
      }
    });
    status = "creating";
    create_capture(body).then(
      (data) => {
        status = "success";
        do_get_captures();
        return data;
      },
      (reason) => {
        status = "failure";
        return Promise.reject(reason);
      }
    );
    close_form();
  }

  function click_close_form() {
    close_form();
  }

  function close_form() {
    create.is_collapsed = true;
    Object.keys(create.api).forEach(function (key) {
      create.api[key as keyof CreateRadiusTraceConfig] = "";
    });
    create.duration = create.durations[0];
  }

  function click_dup_allfields(capture: RadiusTraceConfig) {
    for (const key of duplicable_fields) {
      const value = capture[key];
      if (value) {
        create.api[key] = value;
      }
    }
    create.is_collapsed = false;
  }

  function has_duplicable_fields(capture: RadiusTraceConfig): boolean {
    for (const key of duplicable_fields) {
      if (capture[key]) {
        return true;
      }
    }
    return false;
  }

  function click_delete_capture(capture_id: string) {
    do_delete_capture(capture_id);
  }

  function do_delete_capture(capture_id: string) {
    delete_capture(capture_id).then(
      function (data: any) {
        status = "success";
        do_get_captures();
        return data;
      },
      function (reason: any) {
        status = "failure";
        return Promise.reject(reason);
      }
    );
  }

  $: can_create = true;
  $: captures = sort_captures(unsorted_captures, sort_field, !sort_reverse);
  $: ({get_caret, display_formatted_time} = update(options, create, sort_field, sort_reverse));

  let redraw: ReturnType<typeof setInterval>;
  onMount(() => {
    redraw = setInterval(() => {
      if (options.timezone === "relative time") {
        options.timezone = options.timezone; // Trigger a reactive update
      }
    }, 5000);
  });
  onDestroy(() => {
    clearInterval(redraw);
  });

  do_get_captures();
</script>

<!-- CONTENT -->
<div class="container">
  <!-- PAGE TITLE -->
  <div class="row">
    <div class="col-xs-12">
      <h1>RadSec Captures</h1>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12">
      <h2>
        New Capture
        <button
          disabled={!create.is_collapsed}
          on:click|preventDefault={click_show_capture_creation_form}
          class="btn btn-primary"
        >
          Create Capture
        </button>
      </h2>
    </div>
  </div>

  {#if !create.is_collapsed}
    <form transition:fade class="form-horizontal" name="create_capture_form">
      <div class="row">
        <div class="col-sm-offset-2 col-sm-10">
          <h3>Filters</h3>
        </div>
      </div>
      <div class="form-group">
        <!-- svelte-ignore a11y-label-has-associated-control -->
        <label class="col-sm-2 control-label">Certificate Subject</label>
        <div class="col-sm-8">
          <div class="row">
            <div class="col-xs-8 col-sm-5 col-md-4">
              <input bind:value={create.api.certificate_subject} class="input-md form-control" />
            </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <!-- svelte-ignore a11y-label-has-associated-control -->
        <label class="col-sm-2 control-label">Certificate Serial</label>
        <div class="col-sm-8">
          <div class="row">
            <div class="col-xs-8 col-sm-5 col-md-4">
              <input bind:value={create.api.certificate_serial} class="input-md form-control" />
            </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <!-- svelte-ignore a11y-label-has-associated-control -->
        <label class="col-sm-2 control-label">NAS Identifier</label>
        <div class="col-sm-8">
          <div class="row">
            <div class="col-xs-8 col-sm-5 col-md-4">
              <input bind:value={create.api.nas_identifier} class="input-md form-control" />
            </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <!-- svelte-ignore a11y-label-has-associated-control -->
        <label class="col-sm-2 control-label">NAS MAC</label>
        <div class="col-sm-8">
          <div class="row">
            <div class="col-xs-8 col-sm-5 col-md-4">
              <input bind:value={create.api.nas_mac} class="input-md form-control" />
            </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <!-- svelte-ignore a11y-label-has-associated-control -->
        <label class="col-sm-2 control-label">NAS Network</label>
        <div class="col-sm-8">
          <div class="row">
            <div class="col-xs-8 col-sm-5 col-md-4">
              <input bind:value={create.api.nas_addr} class="input-md form-control" />
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-offset-2 col-sm-10">
          <h3>Time</h3>
        </div>
      </div>
      <div class="form-group">
        <!-- svelte-ignore a11y-label-has-associated-control -->
        <label class="col-sm-2 control-label">Duration</label>
        <div class="col-sm-8">
          <div class="row">
            <div class="col-xs-8 col-sm-5 col-md-4">
              <select bind:value={create.duration} class="form-control">
                {#each create.durations as dur}
                  <option value={dur}>{dur}</option>
                {/each}
              </select>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="col-sm-offset-2 col-sm-8">
          {#if status == "ready" || status == "success"}
            <button on:click|preventDefault={click_create_capture} disabled={!can_create} class="btn btn-primary">
              Start Capture
            </button>
          {/if}
          <button on:click|preventDefault={click_close_form} class="btn btn-primary">Cancel</button>
        </div>
      </div>
    </form>
  {/if}

  <div class="row">
    <div class="col-xs-12">
      <h2>Saved Captures <small class="badge">{num_captures}</small></h2>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12 text-right stack-2">
      <form class="form-inline form-inline-xs">
        <div class="form-group">
          <span>
            <div class="input-group">
              <select bind:value={options.timezone} class="form-control">
                {#each options.timezones as tz}
                  <option value={tz}>{tz}</option>
                {/each}
              </select>
            </div>
          </span>
        </div>
        <div class="form-group">
          <button on:click|preventDefault={click_get_captures} class="btn btn-default">
            <span class="glyphicon glyphicon-refresh" />
            <span class="hidden-xs">Refresh</span>
          </button>
        </div>
      </form>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12">
      <table class="table table-striped table-condensed">
        <thead>
          <tr>
            <th class="text-center" style="width: 17%">Actions</th>
            <th
              on:click|preventDefault={() => {
                set_sort_field("id");
              }}
              class="sortable text-center"
              style="width: 18%"
            >
              Capture ID {get_caret("id")}
            </th>
            <th class="text-center" style="width: 20%">Filters</th>
            <th
              on:click|preventDefault={() => {
                set_sort_field("created_at");
              }}
              class="sortable text-center"
              style="width: 19%"
            >
              Start {get_caret("created_at")}
            </th>
            <th class="text-center" style="width: 2%">&nbsp</th>
            <th
              on:click|preventDefault={() => {
                set_sort_field("expires_at");
              }}
              class="sortable text-center"
              style="width: 19%"
            >
              End {get_caret("expires_at")}
            </th>
            <th class="text-center" style="width: 5%">Duration</th>
          </tr>
        </thead>

        <tbody>
          {#if captures.length}
            {#each captures as capture}
              <tr>
                <td class="text-center" style="width: 17%">
                  <button
                    on:click|preventDefault={() => {
                      click_delete_capture(capture.id);
                    }}
                    class="btn btn-sm"
                  >
                    <span class="glyphicon glyphicon-trash" />
                  </button>
                  <button
                    on:click|preventDefault={() => {
                      click_dup_allfields(capture);
                    }}
                    class="btn btn-sm"
                  >
                    <span class="glyphicon glyphicon-duplicate" />
                  </button>
                </td>
                <td class="text-center" style="width: 18%">{capture.id}</td>
                <td id="filters_for_{capture.id}" class="text-center" style="width: 20%">
                  {#if has_duplicable_fields(capture)}
                    {#each duplicable_fields as f}
                      {#if capture[f]}
                        <div><strong>{f}:</strong>&nbsp{capture[f]}</div>
                      {/if}
                    {/each}
                  {:else}
                    <div>
                      <em>none</em>
                    </div>
                  {/if}
                </td>
                <td class="text-center" style="width: 19%">{display_formatted_time(capture.created_at)}</td>
                <td class="text-center" style="width: 2%">
                  {#if is_running(capture.expires_at)}
                    <Spinner type="circle-fade" />
                  {/if}
                </td>
                <td class="text-center" style="width: 19%">{display_formatted_time(capture.expires_at)}</td>
                <td class="text-center" style="width: 5%">
                  {display_delta_time(capture.created_at, capture.expires_at, false)}
                </td>
              </tr>
            {/each}
          {:else}
            <tr>
              <td colspan="7">
                <em>(no captures exist)</em>
              </td>
            </tr>
          {/if}
        </tbody>
      </table>
    </div>
  </div>
</div>
