import {cg_support_api, type DefaultApiTypes} from "../api";
import type {TaskExecution} from "./task_execution";

export interface TaskHistoryList {
  items: TaskExecution[];
}

interface ApiTypes extends DefaultApiTypes {
  get_result: TaskHistoryList;
}

export function task_history_api(role: string, instance: string, queue_name: string) {
  return cg_support_api<ApiTypes>("/api/v1/queues/task_servers/:role/:instance/history/:queue_name", {
    role,
    instance,
    queue_name,
  });
}
