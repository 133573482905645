<script context="module" lang="ts">
  export type ProgressBarType = "default" | "success" | "info" | "warning" | "danger";
</script>

<script lang="ts">
  export let progress: number | undefined = undefined;
  export let type: ProgressBarType | undefined = undefined;
  export let striped: boolean | undefined = undefined;
  export let active: boolean | undefined = undefined;

  $: percent = progress && progress >= 0 ? progress : 0;
</script>

<div
  class="progress-bar"
  class:progress-bar-success={type === "success"}
  class:progress-bar-info={type === "info"}
  class:progress-bar-warning={type === "warning"}
  class:progress-bar-danger={type === "danger"}
  class:progress-bar-striped={striped || active}
  class:active
  role="progressbar"
  aria-valuenow={percent}
  aria-valuemin={0}
  aria-valuemax={100}
  style:width={`${percent}%`}
>
  <slot />
</div>
