import {writable, type Readable, type Writable} from "svelte/store";
import {Loadable} from "../loader";
import {cg_support_api, type DefaultApiTypes} from "../api";

export interface Param {
  name: string;
  value: string;
}

interface ConfigList {
  items: {[name: string]: string};
}

interface ApiTypes extends DefaultApiTypes {
  get_result: ConfigList;
}

export class Database extends Loadable<ConfigList> {
  #config: Writable<Param[]>;

  constructor() {
    super();

    this.#config = writable([]);
  }

  get config(): Readable<Param[]> {
    return this.#config;
  }

  async $load(): Promise<ConfigList> {
    return cg_support_api<ApiTypes>("/api/v1/services/db-config").get();
  }

  $validate(data: ConfigList): boolean {
    return typeof data === "object" && data !== null && typeof data.items === "object" && data.items !== null;
  }

  $set(data: ConfigList): void {
    this.#config.set(Object.entries(data.items).map(([name, value]) => ({name, value})));
  }

  $clear(): void {
    this.#config.set([]);
  }
}
