<script context="module" lang="ts">
  import {require_nav_context} from "$lib/nav/context";
  import type {NavItem} from "$lib/nav/nav";
  import type {SystemInfoResult} from "./api/services/system_info";
  import {require_system_info} from "./system_info/context";

  function find_active_nav_item(nav: NavItem): NavItem | undefined {
    if (!nav.active) {
      return;
    }

    for (const item of nav.items) {
      const inner = find_active_nav_item(item);
      if (inner) {
        return inner;
      }
    }

    return nav;
  }

  function update_title(nav: NavItem, system_info: SystemInfoResult): string {
    let parts: string[] = [];

    let active = find_active_nav_item(nav);
    if (active && active.label) {
      parts.push(active.label);
    }

    if (system_info.name) {
      parts.push(system_info.name);
    }

    parts.push("Cloud Guest Support");

    return parts.join(" - ");
  }
</script>

<script lang="ts">
  const nav_ctx = require_nav_context();
  const system_info = require_system_info();
  $: nav = $nav_ctx.item;
  $: title = update_title(nav, $system_info);
</script>

<svelte:head>
  <title>{title}</title>
</svelte:head>
