import {cg_support_api, type DefaultApiTypes} from "../api";

export interface TaskServer {
  routing_key: string;
  tasks: string[];
}

export interface TaskServerList {
  items: {
    [queue_name: string]: TaskServer;
  };
}

interface ApiTypes extends DefaultApiTypes {
  get_result: TaskServerList;
}

export function task_servers_api(role: string, instance: string) {
  return cg_support_api<ApiTypes>("/api/v1/queues/task_servers/:role/:instance", {role, instance});
}
