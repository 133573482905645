<script context="module" lang="ts">
  import {require_nav_context} from "$lib/nav/context";
  import type {NavItem} from "$lib/nav/nav";
  import Dropdown from "$lib/Dropdown.svelte";

  function find_active_secondary_group(nav: NavItem): NavItem | undefined {
    if (!nav.active) {
      return;
    }

    for (const item of nav.items) {
      if (item.active && item.level === "secondary") {
        return nav;
      }
    }

    for (const item of nav.items) {
      const group = find_active_secondary_group(item);
      if (group) {
        return group;
      }
    }
  }

  function update_secondary(nav: NavItem): NavItem[] | undefined {
    const group = find_active_secondary_group(nav);
    if (group) {
      return group.items.filter((item) => item.level === "secondary");
    }
  }
</script>

<script lang="ts">
  const nav_ctx = require_nav_context();
  $: nav = $nav_ctx.item;
  $: secondary = update_secondary(nav);
</script>

<header>
  {#if nav.items}
    <div class:with-secondary={secondary ? true : false} class="navbar navbar-inverse">
      <div class="container-fluid">
        <ul class="nav navbar-nav">
          {#each nav.items as primary}
            {#if primary.level === "primary"}
              {#if primary.group}
                <Dropdown
                  tag="li"
                  class={primary.active ? "active" : undefined}
                  toggle_tag="a"
                  options={primary.items}
                  active={(item) => item.active}
                  toggle_aria_label={primary.label || "dropdown"}
                  toggle_tabindex={0}
                >
                  <svelte:fragment slot="toggle">
                    {primary.label}
                    <span class="caret" />
                  </svelte:fragment>
                  <svelte:fragment slot="option" let:option>
                    <a href={option.path} target={option.external ? "_blank" : undefined}>
                      {option.label}
                      {#if option.external}
                        <span class="glyphicon glyphicon-new-window" />
                      {/if}
                    </a>
                  </svelte:fragment>
                </Dropdown>
              {:else}
                <li class:active={primary.active}>
                  {#if primary.path}
                    <a href={primary.path} target={primary.external ? "_blank" : undefined}>
                      {primary.label}
                      {#if primary.external}
                        <span class="glyphicon glyphicon-new-window" />
                      {/if}
                    </a>
                  {:else}
                    <p class="navbar-text">{primary.label}</p>
                  {/if}
                </li>
              {/if}
            {/if}
          {/each}
        </ul>
      </div>
    </div>
    {#if secondary}
      <div class="navbar navbar-default">
        <div class="container-fluid">
          <ul class="nav navbar-nav">
            {#each secondary as item}
              <li class:active={item.active}>
                {#if item.path}
                  <a href={item.path} target={item.external ? "_blank" : undefined}>
                    {item.label}
                    {#if item.external}
                      <span class="glyphicon glyphicon-new-window" />
                    {/if}
                  </a>
                {:else}
                  <p class="navbar-text">{item.label}</p>
                {/if}
              </li>
            {/each}
          </ul>
        </div>
      </div>
    {/if}
  {/if}
</header>
