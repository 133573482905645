import {get, writable, type Readable, type Writable} from "svelte/store";

export interface LoadableState {
  $loading: boolean;
  $error: boolean;
}

export type LoadFn<T> = () => Promise<T>;
export type ValidateFn<T> = (value: T) => boolean;
export type SetFn<T> = (value: T) => void;
export type ClearFn = () => void;

export class Loadable<T> {
  #loading: Writable<boolean>;
  #error: Writable<boolean>;

  constructor() {
    this.#loading = writable(false);
    this.#error = writable(false);
  }

  get $loading(): Readable<boolean> {
    return this.#loading;
  }

  get $error(): Readable<boolean> {
    return this.#error;
  }

  async refresh(): Promise<T | void> {
    return this.$exec(
      () => this.$load(),
      (value) => this.$set(value),
      () => this.$clear(),
      (value) => this.$validate(value)
    );
  }

  async $load(): Promise<T> {
    throw "Not implemented";
  }

  $validate(value: T): boolean {
    return typeof value === "object" && value !== null;
  }

  $set(value: T): void {
    const self: any = this;
    if (typeof value === "object" && value !== null) {
      for (const [k, v] of Object.entries(value)) {
        self[k] = v;
      }
    }
  }

  $clear(): void {
    for (const key of Object.keys(this)) {
      if (typeof key === "string" && key && key[0] !== "$") {
        this.$clear_property(key);
      }
    }
  }

  $clear_property(key: string): void {
    delete (this as any)[key];
  }

  protected async $exec<U = T>(
    load: LoadFn<U>,
    set: SetFn<U>,
    clear: ClearFn,
    validate?: ValidateFn<U>
  ): Promise<U | void> {
    if (!get(this.#loading)) {
      this.#loading.set(true);
      return load()
        .then((result) => {
          if (validate && !validate(result)) {
            return Promise.reject(result);
          }

          this.#loading.set(false);
          this.#error.set(false);

          set(result);

          return result;
        })
        .catch((reason) => {
          this.#loading.set(false);
          this.#error.set(true);
          clear();

          return Promise.reject(reason);
        });
    }
  }
}
