import {writable, type Readable, type Writable} from "svelte/store";
import {Loadable} from "./loader";
import {cg_support_api, type DefaultApiTypes} from "./api";

export interface Error {
  type: string;
  value: string;
  version: string;
  revision: string;
  hostname: string;
  server: string;
  method?: string;
  url?: string;
  headers?: {[name: string]: string};
  args?: string;
  kwargs?: string;
  backtrace?: string;
  timestamp: string;
}

interface ErrorList {
  items: Error[];
}

interface ApiTypes extends DefaultApiTypes {
  get_result: ErrorList;
}

export class Errors extends Loadable<ErrorList> {
  #errors: Writable<Error[]>;

  constructor() {
    super();

    this.#errors = writable([]);
  }

  get errors(): Readable<Error[]> {
    return this.#errors;
  }

  async $load(): Promise<ErrorList> {
    return cg_support_api<ApiTypes>("/api/v1/errors").get({
      calculate_count: true,
      limit: 100,
      sort: "-timestamp",
    });
  }

  $validate(data: ErrorList): boolean {
    return typeof data === "object" && data !== null && typeof data.items === "object" && data.items !== null;
  }

  $set(data: ErrorList): void {
    this.#errors.set(data.items);
  }

  $clear(): void {
    this.#errors.set([]);
  }
}
