<script lang="ts">
  import {email_history} from "$app/support/api/messaging/email_history";
  import type {EmailHistoryResult} from "$app/support/api/messaging/email_history";
  import Alert from "$lib/Alert.svelte";
  import CgDate from "$lib/CgDate.svelte";
  import Spinner from "$lib/spinkit/Spinner.svelte";

  let email = {
    dest_address: "",
    limit: undefined as number | undefined,
    status: "ready" as "ready" | "collecting" | "success" | "failure",
    timezone: "local" as "local" | "UTC",
    timezones: ["local", "UTC"] as ("local" | "UTC")[],
    history: undefined as EmailHistoryResult | undefined,
  };

  function cycle_timezone() {
    const index = email.timezones.indexOf(email.timezone);
    if (index >= 0) {
      const next = (index + 1) % email.timezones.length;
      email.timezone = email.timezones[next];
    }
  }

  function collect() {
    email.status = "collecting";
    email.history = undefined;
    email_history(email.dest_address, email.limit).then(
      function (data) {
        email.status = "success";
        email.history = data;
        return data;
      },
      function (reason) {
        email.status = "failure";
        return Promise.reject(reason);
      }
    );
  }

  function acknowledge() {
    email.status = "ready";
  }

  function reset_form() {
    email.status = "ready";
    email.dest_address = "";
    clear_history();
  }

  function clear_history() {
    email.status = "ready";
    email.history = undefined;
  }

  $: can_collect = !!email.dest_address;
</script>

<div class="container">
  <div class="row">
    <div class="col-sm-offset-2 col-sm-10">
      <h1>Email History</h1>
    </div>
  </div>

  <form class="form-horizontal" name="email_history_form">
    <div class="form-group">
      <!-- svelte-ignore a11y-label-has-associated-control -->
      <label class="col-sm-2 control-label">Timezone</label>
      <div class="col-sm-4">
        <select bind:value={email.timezone} class="form-control">
          {#each email.timezones as tz}
            <option value={tz}>{tz}</option>
          {/each}
        </select>
        <p class="help-block">
          Time data will be displayed in
          <strong>{email.timezone}</strong> timezone.
        </p>
      </div>
    </div>

    <div class="form-group">
      <!-- svelte-ignore a11y-label-has-associated-control -->
      <label class="col-sm-2 control-label">Destination Email</label>
      <div class="col-sm-8">
        <input
          bind:value={email.dest_address}
          on:change={clear_history}
          name="email_dest_address"
          class="form-control"
          required
        />
        {#if !email.dest_address}
          <p class="help-block">Enter the destination email address to retrieve the email history for.</p>
        {/if}
        {#if email.dest_address && email.status != "success"}
          <p class="help-block">
            The email history will be retrieved for the destination address
            <strong>{email.dest_address}</strong>.
          </p>
        {/if}
        {#if email.status == "success"}
          <p class="help-block">
            SUCCESS: Collected
            <strong>{email.history?.items.length ?? 0}</strong> records of email messages sent to the destination
            address
            <strong>{email.dest_address}</strong>.
          </p>
        {/if}
      </div>
    </div>

    <div class="form-group">
      <!-- svelte-ignore a11y-label-has-associated-control -->
      <label class="col-sm-2 control-label">Limit</label>
      <div class="col-sm-4">
        <input bind:value={email.limit} on:change={clear_history} class="form-control" placeholder="10" required />
        <p class="help-block">The maximum number of matching records to display.</p>
      </div>
    </div>

    <div class="form-group">
      <div class="col-sm-offset-2 col-sm-8">
        {#if email.status === "ready" || email.status === "success"}
          <button on:click|preventDefault={collect} disabled={!can_collect} class="btn btn-lg btn-primary">
            {#if email.status === "ready"}
              Collect
            {:else}
              <span class="glyphicon glyphicon-refresh" /> Refresh
            {/if}
          </button>
        {/if}
        {#if email.status === "collecting"}
          <button disabled class="btn btn-lg btn-primary">
            Collecting
            <Spinner type="circle-fade" />
          </button>
        {/if}
        {#if email.status === "success"}
          <button on:click|preventDefault={reset_form} class="btn btn-lg btn-default">Clear</button>
        {/if}
        {#if email.status === "failure"}
          <Alert type="danger" close={acknowledge}>Failed to collect the email history</Alert>
        {/if}
      </div>
    </div>

    <div class="form-group">
      <div class="col-xs-12 col-sm-offset-2 col-sm-10">
        {#if email.history}
          <table class="table table-striped table-condensed">
            <thead>
              <tr>
                <th>Updated</th>
                <th>To</th>
                <th>Subject</th>
                <th class="text-right">Status</th>
              </tr>
            </thead>
            <tbody>
              {#each email.history.items as msg}
                <tr>
                  <td>
                    <CgDate
                      date={msg.last_event_time}
                      format={["shortDate", "mediumTime"]}
                      utc={email.timezone === "UTC"}
                    />
                    <!-- svelte-ignore a11y-missing-attribute -->
                    <!-- svelte-ignore a11y-click-events-have-key-events -->
                    <!-- svelte-ignore a11y-no-static-element-interactions -->
                    <a on:click|preventDefault={cycle_timezone} class="text-muted">
                      {email.timezone.toLowerCase()}
                    </a>
                  </td>
                  <td>{msg.to_email}</td>
                  <td>{msg.subject}</td>
                  <td
                    class="text-right"
                    class:text-success={msg.status === "delivered"}
                    class:text-danger={msg.status !== "delivered"}
                  >
                    <strong>{msg.status}</strong>
                  </td>
                </tr>
              {/each}
            </tbody>
          </table>
        {/if}
      </div>
    </div>
  </form>
</div>
