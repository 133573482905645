<script context="module" lang="ts">
  import {onDestroy} from "svelte";
  import {SystemHealth} from "$app/support/api/services/system_health";
  import type {Role, RoleInstance} from "$app/support/api/services/system_health";
  import {system_version} from "$app/support/api/services/system_version";
  import {require_system_health_status, set_system_health_status} from "$app/support/system_health/context";
  import type {SystemHealthStatus} from "$app/support/system_health/context";
  import {format_iso8601} from "$lib/date";
  import Progress from "$lib/Progress.svelte";
  import ProgressBar from "$lib/ProgressBar.svelte";
  import RefreshButton from "$lib/RefreshButton.svelte";

  interface Options {
    filter: "all" | "pass" | "fail";
  }

  function update_status(status: SystemHealthStatus) {
    return {status, status_time: new Date().getTime()};
  }

  function update_options(options: Options) {
    function show_health_for(obj: SystemHealth | Role | RoleInstance): boolean {
      if (options.filter === "all") {
        return true;
      }
      switch (obj.status()) {
        case "loading":
        case "pass":
          return options.filter === "pass";
        case "error":
        case "fail":
        default:
          return options.filter === "fail";
      }
    }

    return {show_health_for};
  }
</script>

<script lang="ts">
  const last_system_health = require_system_health_status();
  const system = new SystemHealth();
  let version = "";
  let version_status: "loading" | "done" | "error" = "loading";
  let status: SystemHealthStatus | undefined = undefined;
  let status_time: number | undefined = undefined;
  let status_time_ago = "";
  const options = {
    filter: "fail" as "all" | "pass" | "fail",
  };

  function refresh() {
    if (status !== "loading") {
      status_time = undefined;
      status_time_ago = "";
      system.refresh();
    }
  }

  $: set_system_health_status($system.status());
  $: ({status, status_time} = update_status($last_system_health));
  $: ({show_health_for} = update_options(options));

  system.refresh();

  system_version().then(
    (data) => {
      version = data;
      version_status = "done";
    },
    (reason) => {
      console.log(reason);
      version_status = "error";
    }
  );

  const interval = setInterval(() => {
    if (status_time && status !== "loading") {
      status_time_ago = format_iso8601(status_time, "moment").toString();
    }
  }, 10 * 1000);

  onDestroy(() => {
    clearInterval(interval);
    set_system_health_status("normal");
  });
</script>

<div class="container">
  <div class="row">
    <div class="col-xs-12">
      <h1>System Health</h1>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12 col-sm-6">
      {#if version_status === "done"}
        <span class="text-muted">Version&nbsp;{version}</span>
      {:else if version_status === "error"}
        <span class="text-danger">Failed to get Version</span>
      {/if}
    </div>
    <div class="col-xs-12 col-sm-6 text-right">
      <span class="text-muted">{status === "loading" ? "Loading..." : status_time_ago}</span>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12 text-right stack-2">
      <span class="btn-group">
        <button type="button" class="btn btn-default">
          <span class="glyphicon glyphicon-filter" />
        </button>
        <button
          on:click|preventDefault={() => {
            options.filter = "all";
          }}
          class:btn-primary={options.filter === "all"}
          class:btn-default={options.filter !== "all"}
          type="button"
          class="btn"
        >
          All
        </button>
        <button
          on:click|preventDefault={() => {
            options.filter = "pass";
          }}
          class:btn-primary={options.filter === "pass"}
          class:btn-default={options.filter !== "pass"}
          type="button"
          class="btn"
        >
          Pass
        </button>
        <button
          on:click|preventDefault={() => {
            options.filter = "fail";
          }}
          class:btn-primary={options.filter === "fail"}
          class:btn-default={options.filter !== "fail"}
          type="button"
          class="btn"
        >
          Fail
        </button>
      </span>
      <RefreshButton auto_refresh={true} onrefresh={refresh} />
    </div>
  </div>

  <div class="row system-health-summary">
    <div class="col-xs-6 col-sm-4 col-md-3 stack-1">
      <div class="indent-0">
        <strong>Status</strong>
      </div>
    </div>
    <div class="col-xs-6 col-sm-4 col-md-3 stack-1">
      {#if status === "loading"}
        <span class="label label-default">Loading</span>
      {:else if status === "error"}
        <span class="label label-warning">Error</span>
      {:else if status === "pass"}
        <span class="label label-success">Pass</span>
      {:else if status === "fail"}
        <span class="label label-danger">Fail</span>
      {/if}
    </div>
    <div class="col-xs-12 col-sm-4 col-md-6 stack-1">
      <Progress class="progress-system-health">
        <ProgressBar progress={$system.percentage("pass")} type="success" />
        <ProgressBar progress={$system.percentage("fail")} type="danger" />
        <ProgressBar progress={$system.percentage("error")} type="warning" />
      </Progress>
    </div>
  </div>

  <div class="row system-health-summary">
    <div class="col-xs-6 col-sm-4 col-md-3 stack-1">
      <div class="indent-1">
        <strong>Services</strong>
      </div>
    </div>
    <div class="col-xs-6 col-sm-4 col-md-3 stack-1">
      {#if status === "loading"}
        <span class="label label-default">Loading</span>
      {:else if status === "error"}
        <span class="label label-warning">Error</span>
      {:else if status === "pass"}
        <span class="label label-success">Pass</span>
      {:else if status === "fail"}
        <span class="label label-danger">Fail</span>
      {/if}
    </div>
    <div class="col-xs-12 col-sm-4 col-md-6 stack-1">
      {#if $system.error}
        <span>Failed to load the list of services</span>
      {:else}
        <Progress class="progress-system-health">
          <ProgressBar progress={$system.percentage("pass")} type="success" />
          <ProgressBar progress={$system.percentage("fail")} type="danger" />
          <ProgressBar progress={$system.percentage("error")} type="warning" />
        </Progress>
      {/if}
    </div>
  </div>

  {#each $system.roles as role}
    {#if show_health_for(role)}
      {@const role_status = role.status()}
      <div class="row system-health-summary">
        <div class="col-xs-6 col-sm-4 col-md-3 stack-1">
          <div class="indent-2">
            <strong>{role.name}</strong>
          </div>
        </div>
        <div class="col-xs-6 col-sm-4 col-md-3 stack-1">
          {#if role_status === "loading"}
            <span class="label label-default">Loading</span>
          {:else if role_status === "error"}
            <span class="label label-warning">Error</span>
          {:else if role_status === "pass"}
            <span class="label label-success">Pass</span>
          {:else if role_status === "fail"}
            <span class="label label-danger">Fail</span>
          {/if}
        </div>
        <div class="col-xs-12 col-sm-4 col-md-6 stack-1">
          {#if role.error}
            <span>
              Failed to load the list of instances for the <strong>{role.name}</strong> service
            </span>
          {:else}
            <Progress class="progress-system-health">
              <ProgressBar progress={role.percentage("pass")} type="success" />
              <ProgressBar progress={role.percentage("fail")} type="danger" />
              <ProgressBar progress={role.percentage("error")} type="warning" />
            </Progress>
          {/if}
        </div>
      </div>
    {/if}
  {/each}

  <div class="row system-health-summary">
    <div class="col-xs-6 col-sm-4 col-md-3 stack-1">
      <div class="indent-1">
        <strong>Instances</strong>
      </div>
    </div>
    <div class="col-xs-6 col-sm-4 col-md-3 stack-1">
      {#if status === "loading"}
        <span class="label label-default">Loading</span>
      {:else if status === "error"}
        <span class="label label-warning">Error</span>
      {:else if status === "pass"}
        <span class="label label-success">Pass</span>
      {:else if status === "fail"}
        <span class="label label-danger">Fail</span>
      {/if}
    </div>
    <div class="col-xs-12 col-sm-4 col-md-6 stack-1">
      {#if $system.error}
        <span>Failed to load the list of services</span>
      {:else}
        <Progress class="progress-system-health">
          <ProgressBar progress={$system.percentage("pass")} type="success" />
          <ProgressBar progress={$system.percentage("fail")} type="danger" />
          <ProgressBar progress={$system.percentage("error")} type="warning" />
        </Progress>
      {/if}
    </div>
  </div>

  {#each $system.roles as role}
    {#each role.instances as instance}
      {#if show_health_for(instance)}
        {@const instance_status = instance.status()}
        {@const instance_health = instance.health}
        <div class="row system-health-summary">
          <div class="col-xs-6 col-sm-4 col-md-3 stack-1">
            <div class="indent-2">
              <strong>{instance.name}/{instance.ip}</strong>
            </div>
          </div>
          <div class="col-xs-6 col-sm-4 col-md-3 stack-1">
            {#if instance_status === "loading"}
              <span class="label label-default">Loading</span>
            {:else if instance_status === "error"}
              <span class="label label-warning">Error</span>
            {:else if instance_status === "pass"}
              <span class="label label-success">Pass</span>
            {:else if instance_status === "fail"}
              <span class="label label-danger">Fail</span>
            {/if}
          </div>
          <div class="col-xs-12 col-sm-4 col-md-6 stack-1">
            {#if instance.error}
              <span>Failed to load the health checks</span>
            {:else}
              <Progress class="progress-system-health">
                <ProgressBar progress={instance.percentage("pass")} type="success" />
                <ProgressBar progress={instance.percentage("fail")} type="danger" />
                <ProgressBar progress={instance.percentage("error")} type="warning" />
              </Progress>
            {/if}
          </div>
        </div>
        {#each Object.entries(instance_health ? instance_health.checks : {}) as [check, message]}
          {#if options.filter === "all" || (options.filter === "pass" && !message) || (options.filter === "fail" && message)}
            <div class="row health-check">
              <div class="col-xs-6 col-sm-4 col-md-3 stack-1">
                <div class="indent-3">
                  <span>{check}</span>
                </div>
              </div>
              <div class="col-xs-6 col-sm-4 col-md-3 stack-1">
                {#if !message}
                  <span class="label label-success">Pass</span>
                {:else}
                  <span class="label label-danger">Fail</span>
                {/if}
              </div>
              <div class="col-xs-12 col-sm-4 col-md-6 stack-1">
                <span>{message ?? ""}</span>
              </div>
            </div>
          {/if}
        {/each}
      {/if}
    {/each}
  {/each}
</div>
