import {Connection, type Params as ConnectionParams} from "./connection";
import {Connections} from "./connections";
import {ProxyConnections} from "./proxy_connections";
import {Instances} from "./instances";
import {Statistics} from "./stats";

export class RadSec {
  #connections: Connections;
  #instances: Instances;
  #proxy_connections: ProxyConnections;
  #proxy_stats: Statistics;
  #stats: Statistics;

  constructor() {
    this.#connections = new Connections();
    this.#instances = new Instances();
    this.#proxy_connections = new ProxyConnections();
    this.#proxy_stats = new Statistics("proxy_stats");
    this.#stats = new Statistics("stats");
  }

  connection(params: ConnectionParams): Connection {
    return new Connection(params);
  }

  get connections() {
    return this.#connections;
  }

  get instances() {
    return this.#instances;
  }

  get proxy_connections() {
    return this.#proxy_connections;
  }

  get proxy_stats() {
    return this.#proxy_stats;
  }

  get stats() {
    return this.#stats;
  }
}
