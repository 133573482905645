import {cg_support_session_key} from "$lib/session";
import {CgUrl, type UrlParam, type UrlParams} from "$lib/url";

export type Data = {[key: string]: any};
export type Params = {
  [key: string]: UrlParam | (() => UrlParam);
};

export interface DefaultApiTypes {
  get_result: any;
  get_params: Params | undefined;
  delete_result: any;
  delete_params: Params | undefined;
  post_result: any;
  post_params: Params | undefined;
  post_data: Data | undefined;
}

export type EventType = "unauthorized";
export type UnauthorizedEvent = Response;
export type EventParams = UnauthorizedEvent;
export type UnauthorizedEventHandler = (event: UnauthorizedEvent) => void;
export type EventHandler = UnauthorizedEventHandler;
export type DeregisterFn = () => void;
export type EventHandlers = {
  unauthorized: UnauthorizedEventHandler[];
};

export type TransformFn = (value: any) => any;

const handlers: EventHandlers = {unauthorized: []};

function copy(params: Params): Params {
  return {...params};
}

function extend(params: Params, ...extensions: Params[]): Params {
  for (const ext of extensions) {
    for (const [k, v] of Object.entries(ext)) {
      params[k] = v;
    }
  }
  return params;
}

export function cg_support_api<TApiTypes extends DefaultApiTypes = DefaultApiTypes>(
  url: string,
  param_defaults?: Params
) {
  // Private data
  param_defaults = copy(param_defaults ?? {});

  // Private functions
  function make_config(method: string, params?: Params, data?: Data): Request {
    const config: RequestInit = {};

    const req_params = extend({}, param_defaults ?? {}, params ?? {});
    const url_params: UrlParams = {};
    const headers: HeadersInit = {"Content-Type": "application/json"};
    for (let [key, value] of Object.entries(req_params)) {
      if (typeof value === "function") {
        value = value();
      }
      if (key && key.charAt(0) === "$") {
        key = key.slice(1);
        if (key && typeof value === "string" && value !== "") {
          headers[key] = value;
        }
      } else if (typeof value !== "undefined") {
        url_params[key] = value;
      }
    }

    config.body = typeof data === "undefined" ? undefined : JSON.stringify(data);
    config.method = method;
    config.headers = headers;

    const api_key = cg_support_api.get_api_key();
    if (api_key) {
      config.headers["X-CloudGuest-API-Key"] = api_key;
    }

    let encoded_url = CgUrl.url_from_template(url, url_params);

    if (import.meta.hot) {
      // This code only exists when running with HMR from the Vite Dev Server.
      if (encoded_url && encoded_url[0] === "/") {
        encoded_url = `/vite-dev-support-portal-proxy${encoded_url}`;
      }
    }

    return new Request(encoded_url, config);
  }

  //
  // Api - Object returned by the API factory for handling API requests.
  //
  class Api {
    request<T = any>(method: string, params?: Params, data?: Data): Promise<T> {
      return fetch(make_config(method, params, data)).then(function (response) {
        if (response.status == 204) {
          return;
        } else if (response.status == 200 || response.status == 201) {
          if ((response.headers.get("Content-Type") ?? "").trim().startsWith("text/")) {
            return response.text();
          } else {
            return response.json();
          }
        } else {
          if (response.status == 401) {
            cg_support_api.trigger("unauthorized", response);
          }
          return Promise.reject(response);
        }
      });
    }

    delete(params?: TApiTypes["delete_params"]) {
      return this.request<TApiTypes["delete_result"]>("DELETE", params);
    }

    get(params?: TApiTypes["get_params"]) {
      return this.request<TApiTypes["get_result"]>("GET", params);
    }

    post(params?: TApiTypes["post_params"], data?: TApiTypes["post_data"]) {
      return this.request<TApiTypes["post_result"]>("POST", params, data);
    }
  }

  return new Api();
}

//
// "static" API functions shared by all API instances.
//

cg_support_api.get_api_key = cg_support_session_key;

cg_support_api.on = function (event_name: EventType, handler: EventHandler): DeregisterFn {
  let cancelled = false;

  let event_handlers = handlers[event_name];
  if (typeof event_handlers === "undefined") {
    handlers[event_name] = event_handlers = [];
  }
  event_handlers.push(handler);

  // Return a "cancellation" function that will remove this event handler
  // if invoked.
  return () => {
    if (!cancelled) {
      cg_support_api.off(event_name, handler);
    }
    cancelled = true;
  };
};

cg_support_api.off = function (event_name: EventType, handler: EventHandler): void {
  let ehs = handlers[event_name];

  if (typeof ehs === "undefined") {
    return;
  }

  for (let i = ehs.length - 1; i >= 0; --i) {
    if (ehs[i] === handler) {
      ehs.splice(i, 1);
      // Only remove the handler "once".  If it has been registered more
      // than once, then "off" needs to be called for each corresponding
      // "on" registration.
      break;
    }
  }
};

cg_support_api.trigger = function (event_name: EventType, event: EventParams): void {
  for (const handler of handlers[event_name] ?? []) {
    handler(event);
  }
};

cg_support_api.to_array = function (obj: Data, key_name?: string, transform?: TransformFn) {
  let result: any[] = [];

  for (let [key, value] of Object.entries(obj)) {
    if (transform) {
      value = transform(value);
    }

    if (key_name) {
      value[key_name] = key;
    }

    result.push(value);
  }

  return result;
};

cg_support_api.counts_to_array = function (obj: Data) {
  return cg_support_api.to_array(obj, "code", function (count) {
    return {count: count};
  });
};
