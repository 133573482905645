<script context="module" lang="ts">
  import {onDestroy} from "svelte";
  import {cg_support_api} from "./api/api";
  import Lightbox from "$lib/Lightbox.svelte";

  async function is_authorized(): Promise<boolean> {
    if (cg_support_api.get_api_key()) {
      return true;
    } else if (import.meta.hot) {
      // This code will not run in production as HMR does not exist. The build
      // replaces `import.meta.hot` with false.

      // In dev-mode we get our API by simply accessing the support portal.
      console.log("contacting Vite dev-server to acquire session cookie");
      return cg_support_api("/")
        .get()
        .then(
          (html) => {
            if (typeof html !== "string") {
              console.log("unexpected response from Vite dev-server when attempting to acquire session cookie", html);
            }
          },
          (reason) => {
            console.log("failed to use Vite dev-server to acquire session cookie", reason);
          }
        )
        .then(() => !!cg_support_api.get_api_key());
    } else {
      return false;
    }
  }
</script>

<script lang="ts">
  let authorized = is_authorized();

  const deregister = cg_support_api.on("unauthorized", (event) => {
    authorized = Promise.resolve(false);
  });

  onDestroy(deregister);
</script>

{#await authorized then authorized}
  {#if !authorized}
    <Lightbox>
      <div class="col-sm-offset-2 col-sm-8">
        <div class="panel panel-danger">
          <div class="panel-heading">
            <span class="glyphicon glyphicon-ban-circle" /> <strong>Authorization Required</strong>
          </div>
          <div class="panel-body">
            <p>You are not signed in, or your session has timed out.</p>
            <p><a href="/login">Sign in</a> to use this support portal.</p>
            <div class="text-right">
              <a class="btn btn-danger" href="/login">Sign in</a>
            </div>
          </div>
        </div>
      </div>
    </Lightbox>
  {/if}
{/await}
