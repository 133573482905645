import {writable, type Readable, type Writable} from "svelte/store";
import {Loadable} from "../loader";
import {cg_support_api, type DefaultApiTypes} from "../api";

interface TestScopeResult {
  scope_path: string;
  api_key: string;
  capture_url: string;
  nas_identifier: string;
}

interface ApiTypes extends DefaultApiTypes {
  get_result: TestScopeResult;
  post_result: TestScopeResult;
}

export class TestScope extends Loadable<TestScopeResult> {
  #test_scope: Writable<TestScopeResult>;

  constructor() {
    super();

    this.#test_scope = writable(default_test_scope());
  }

  get test_scope(): Readable<TestScopeResult> {
    return this.#test_scope;
  }

  async $load(): Promise<TestScopeResult> {
    return cg_support_api<ApiTypes>("/api/v1/test_scope").get();
  }

  $validate(data: TestScopeResult): boolean {
    return typeof data === "object" && data !== null;
  }

  $set(data: TestScopeResult): void {
    this.#test_scope.set(data);
  }

  $clear(): void {
    this.#test_scope.set(default_test_scope());
  }

  async post(): Promise<TestScopeResult | void> {
    return this.$exec<TestScopeResult>(
      () => cg_support_api<ApiTypes>("/api/v1/test_scope").post(),
      (data) => this.$set(data),
      () => this.$clear(),
      (data) => this.$validate(data)
    );
  }

  async delete(): Promise<void> {
    return this.$exec<void>(
      () => cg_support_api<ApiTypes>("/api/v1/test_scope").delete(),
      (data) => this.$set(default_test_scope()),
      () => this.$clear()
    );
  }

  async regenerate_certificates(): Promise<void> {
    return cg_support_api("/api/v1/test_scope/regenerate_certificates").post();
  }
}

function default_test_scope(): TestScopeResult {
  return {scope_path: "", api_key: "", capture_url: "", nas_identifier: ""};
}
